<template>
  <div>
    <GrModal
      name="visualizar-lead"
      id="visualizar-lead"
      idModal="visualizar-lead"
      ref="visualizar-lead"
      size="sl"
      title=""
      @shown="openModal"
      :hideHeader="true"
      :hideFooter="true"
      @hidden="execHiddenModal"
    >
      <div
        :class="{ is_loading: loading }"
        style="display: flex; gap: 30px; height: 100%; padding: 20px 0"
        @click="currentModal = ''"
      >
        <div class="close-modal">
          <img
            style="width: 31px; position: absolute; right: 18px; top: 18px; cursor: pointer"
            src="@/assets/img/icons/fechar.svg"
            @click="closeModal"
            alt="close"
          />
        </div>
        <div class="lead-aside" v-if="!loading">
          <div class="lead-data">
            <div
              v-if="lead.id"
              class="lead-picture"
              :style="
                leadPicture
                  ? `background-color: transparent; background-image: url(${leadPicture});`
                  : ''
              "
            >
              <div class="lead-initial" v-if="lead.name && !leadPicture">
                {{ getMetaInitialLead(lead.name) }}
              </div>
              <div @click="openModalPhoto()" class="add-img-lead">
                <span>
                  <img src="@/assets/icons/edit.svg" />
                </span>
              </div>
            </div>
            <div class="lead-texts">
              <div style="display: flex; justify-content: space-between">
                <div class="d-flex">
                  <p class="lead-name">{{ lead.name }}</p>
                  <span class="dot deg">◉</span>
                  <span class="lead-score">{{ lead.leadscore }}</span>
                  <span class="deg">°</span>
                </div>
                <img
                  src="@/assets/icons/edit.svg"
                  class="cursor-pointer"
                  v-b-tooltip.hover
                  title="Editar informações do lead"
                  @click.stop="
                    currentModal == 'lead' ? (currentModal = '') : (currentModal = 'lead')
                  "
                />
              </div>
              <p style="overflow: hidden; max-width: 100%; text-overflow: ellipsis">
                {{ lead.email }}
              </p>
              <div>
                <p
                  style="margin-top: 0"
                  class="lead-phone"
                  :class="lead.phone ? 'lead-phone-hover' : ''"
                  @click.stop="lead.phone ? redirectPhone(lead.phone) : ''"
                >
                  {{ lead.phone ? lead.phone : "Sem telefone" }}
                  <img
                    style="display: inline-block; height: 18px; margin-left: 3px"
                    src="@/assets/icons/whats-black.svg"
                    alt="whats"
                    v-if="lead.phone"
                  />
                </p>
              </div>
            </div>

            <UpdateLead
              :current-modal="currentModal"
              :lead="lead"
              :address="lead.address"
              :translations="translations"
              :leadsUnification="leadsUnification"
              @save="updateDataLead"
            />
          </div>

          <!-- Endereço -->
          <ViewAddress v-if="leadsUnification" :lead="lead" />

          <!-- Tags -->
          <div class="lead-tags sub-card mt-2" style="padding-bottom: 13px">
            <div v-if="tags.length">
              <span
                class="add-tag"
                @click.stop="currentModal == 'tags' ? (currentModal = '') : (currentModal = 'tags')"
              >
                + Adicionar tag

                <section
                  v-if="currentModal == 'tags'"
                  class="lead-tags drop-bottom"
                  id="tags-all"
                  @click.stop="&quot;&quot;;"
                >
                  <img src="@/assets/img/icons/search.svg" class="Pointer searchIcon" />
                  <input type="text" v-model="tagSearch" />
                  <div v-if="tags_props.length">
                    <!-- <span class="add-tag" @click="openModalTag">
                    + Criar
                  </span> -->
                    <span
                      v-for="(item, index) in filterSearchTags"
                      :key="index"
                      style="margin: 0; padding: 0"
                    >
                      <span
                        v-if="!tags.some((tag) => tag.id == item.id)"
                        @click="addTag(item)"
                        class="container-tag"
                        :class="`${item.color ? item.color : 'default'}_color`"
                        ><span class="lead-tag-name">{{ item.name }} </span></span
                      >
                    </span>
                    <span
                      class="add-tag"
                      v-if="tags.length == tags_props.length"
                      style="background-color: transparent !important; cursor: default !important"
                      >Todas as tags selecionadas</span
                    >
                  </div>
                  <span v-else>Nenhuma tag associada ao lead</span>
                </section>
              </span>
              <span
                :class="`${item.color ? item.color : 'default'}_color`"
                v-for="(item, index) in currentTags"
                :key="index"
              >
                <span class="lead-tag-name">{{ item.name }} </span>

                <span class="delete-tag" @click="removeTag(item)">✕</span>
              </span>
              <span class="add-tag" v-show="tags.length > 10" @click="showAllTags = !showAllTags">
                {{ showAllTags ? "Ver menos" : "Ver tudo" }}
              </span>
            </div>
            <span
              v-else
              style="margin: 0"
              class="add-tag"
              @click.stop="currentModal == 'tags' ? (currentModal = '') : (currentModal = 'tags')"
            >
              + Adicionar tag
              <section
                v-if="currentModal == 'tags'"
                class="lead-tags drop-bottom"
                id="tags-all"
                @click.stop="&quot;&quot;;"
              >
                <img src="@/assets/img/icons/search.svg" class="Pointer searchIcon" />
                <input type="text" v-model="tagSearch" />
                <div v-if="tags_props.length">
                  <!-- <span class="add-tag" @click="openModalTag">
                  + Criar
                </span> -->
                  <span
                    v-for="(item, index) in filterSearchTags"
                    :key="index"
                    style="margin: 0; padding: 0"
                  >
                    <span
                      v-if="!tags.some((tag) => tag.id == item.id)"
                      @click="addTag(item)"
                      class="container-tag"
                      :class="`${item.color ? item.color : 'default'}_color`"
                      ><span class="lead-tag-name">{{ item.name }} </span></span
                    >
                  </span>
                </div>
                <span v-else>Nenhuma tag associada ao lead</span>
              </section>
            </span>
            <!-- Edit tags -->
          </div>

          <!-- Follow Up -->
          <h4 class="title" style="display: flex; justify-content: space-between">Follow-up</h4>
          <div class="sub-card">
            <date-range-picker
              style="border-radius: 10px !important; width: 100%"
              ref="picker"
              opens="center"
              :locale-data="localeData"
              :showWeekNumbers="false"
              :showDropdowns="true"
              :autoApply="false"
              :ranges="false"
              :timePicker24Hour="true"
              :timePicker="true"
              v-model="dateRange1"
              :single-date-picker="true"
              @update="editFollowUp"
              v-if="renderDate"
            >
              <template v-if="!dateRange1.startDate" v-slot:input>
                <div
                  style="display: flex; width: 100%; height: 100%; align-items: center; gap: 15px"
                >
                  <i class="fa fa-calendar"></i>
                  <p style="margin-bottom: 0 !important">Selecione a data</p>
                </div>
              </template>
            </date-range-picker>
            <input type="text" class="w-100" v-else style="margin-bottom: 10px !important" />
            <div v-if="hasAccount" class="sub-card" style="padding: 10px 15px; margin-bottom: 0">
              <b-form-checkbox
                v-model="google_calendar"
                name="is_main"
                size="lg"
                switch
                class="w-100"
              >
                <p class="info-checkbox">Salvar no Google Agenda</p>
              </b-form-checkbox>
              <b-collapse id="google-calendar" v-model="google_calendar">
                <label>Título do evento</label>
                <input
                  class="w-100"
                  type="text"
                  v-model="event_title"
                  name="event_title"
                  id="event_title"
                />
                <label>Duração - {{ meetingDuration }} minutos</label>
                <input type="range" v-model="meetingDuration" min="5" max="180" step="5" />
                <button class="btn-follow w-100" @click="saveGoogleEvent">
                  Agendar com google
                </button>
              </b-collapse>
            </div>
            <button v-if="dateRange1.startDate" class="btn-follow w-100 mt-2" @click="removeFollow">
              Remover follow-up
            </button>
          </div>

          <!-- Sellers -->
          <h4 class="title">Vendedores</h4>
          <div class="sub-card">
            <div
              class="sub-card mb-2 cursor-pointer vendedores"
              @click.stop="selectSellers('pré-vendedor')"
              v-show="config.hasPreSeller"
            >
              <div style="display: flex; align-items: center">
                <div style="margin-right: 10px">
                  <div
                    v-if="
                      pre_vendedor &&
                      pre_vendedor.metas &&
                      pre_vendedor.metas.length &&
                      pre_vendedor.metas.find((x) => x.meta_key === 'photo') != undefined &&
                      pre_vendedor.metas.find((x) => x.meta_key === 'photo').meta_value
                    "
                  >
                    <img
                      style="margin: 0 !important"
                      class="seller-photo"
                      :src="pre_vendedor.metas.find((x) => x.meta_key === 'photo').meta_value"
                      alt=""
                    />
                  </div>
                  <div
                    v-else-if="lead && lead.pre_user_id"
                    class="seller-no-ft"
                    style="margin: 0 !important"
                  >
                    <h3 style="text-align: center">
                      {{ getMetaInitial(lead.pre_user_id) }}
                    </h3>
                  </div>
                  <img
                    v-else
                    style="margin: 0 !important"
                    class="user-icon"
                    src="@/assets/icons/no-seller.svg"
                    alt=""
                  />
                </div>
                <span class="sellers-name">
                  <label style="margin: 0 !important; cursor: pointer" for="">Pré-vendedor</label>
                  {{
                    pre_vendedor
                      ? `${pre_vendedor.first_name ? pre_vendedor.first_name : ""} ${
                          pre_vendedor.last_name ? pre_vendedor.last_name : ""
                        }`
                      : "Selecione um pré-vendedor"
                  }}
                </span>
              </div>
              <div
                v-if="currentModal == 'pre-seller'"
                class="drop-top"
                id="tags-all"
                @click.stop="&quot;&quot;;"
              >
                <img src="@/assets/img/icons/search.svg" class="Pointer searchIcon" />
                <input type="text" v-model="sellerSearch" />
                <div v-if="sellers.length">
                  <div
                    style="padding: 5px 10px !important"
                    class="sub-card cursor-pointer vendedores"
                    :style="
                      (sellerWord == 'vendedor' && !vendedor) ||
                      (sellerWord != 'vendedor' && !pre_vendedor)
                        ? 'cursor: default; background-color: #f1f1f1 !important;'
                        : ''
                    "
                    @click.stop="
                      (sellerWord == 'vendedor' && !vendedor) ||
                      (sellerWord != 'vendedor' && !pre_vendedor)
                        ? ''
                        : removerVendedor(sellerWord == 'vendedor' ? 'user' : 'pre_user')
                    "
                  >
                    <div style="display: flex; align-items: center">
                      <div style="margin-right: 10px">
                        <img
                          style="margin: 0 !important"
                          class="user-icon"
                          src="@/assets/icons/no-seller.svg"
                          alt=""
                        />
                      </div>
                      <span>
                        <label
                          :style="
                            (sellerWord == 'vendedor' && !vendedor) ||
                            (sellerWord != 'vendedor' && !pre_vendedor)
                              ? 'cursor: default;'
                              : 'cursor: pointer;'
                          "
                          style="margin: 0 !important"
                          for=""
                          >Sem {{ sellerWord }}</label
                        >
                        Sem {{ sellerWord }}
                      </span>
                    </div>
                  </div>
                  <div
                    style="padding: 5px 10px !important"
                    v-for="(item, index) in filterSearchSellers"
                    :key="index"
                    class="sub-card cursor-pointer vendedores"
                    :style="
                      (sellerWord == 'vendedor' && vendedor && item.id == vendedor.id) ||
                      (sellerWord != 'vendedor' && pre_vendedor && item.id == pre_vendedor.id)
                        ? 'cursor: default; background-color: #f1f1f1 !important;'
                        : ''
                    "
                    @click.stop="
                      (sellerWord == 'vendedor' && vendedor && item.id == vendedor.id) ||
                      (sellerWord != 'vendedor' && pre_vendedor && item.id == pre_vendedor.id)
                        ? ''
                        : editVendedor(item.id, sellerWord == 'vendedor' ? 'user' : 'pre_user')
                    "
                  >
                    <div style="display: flex; align-items: center">
                      <div style="margin-right: 10px">
                        <div
                          v-if="
                            item &&
                            item.metas &&
                            item.metas.length &&
                            item.metas.find((x) => x.meta_key === 'photo') &&
                            item.metas.find((x) => x.meta_key === 'photo').meta_value
                          "
                        >
                          <img
                            style="margin: 0 !important"
                            class="seller-photo"
                            :src="item.metas.find((x) => x.meta_key === 'photo').meta_value"
                            alt=""
                          />
                        </div>
                        <div v-else class="seller-no-ft" style="margin: 0 !important">
                          <h3 style="text-align: center">
                            {{ getMetaInitial(item.id) }}
                          </h3>
                        </div>
                      </div>
                      <span class="sellers-name">
                        <label
                          :style="
                            (sellerWord == 'vendedor' && vendedor && item.id == vendedor.id) ||
                            (sellerWord != 'vendedor' && pre_vendedor && item.id == pre_vendedor.id)
                              ? 'cursor: default;'
                              : 'cursor: pointer;'
                          "
                          style="margin: 0 !important"
                          for=""
                          >{{ item.first_name ? item.first_name : "" }}
                          {{ item.last_name ? item.last_name : "" }}</label
                        >
                        {{ item.email }}
                      </span>
                    </div>
                  </div>
                </div>
                <span v-else>Nenhum vendedor encontrado</span>
              </div>
            </div>

            <div
              class="sub-card cursor-pointer vendedores"
              style="margin: 0"
              @click.stop="selectSellers('vendedor')"
            >
              <div style="display: flex; align-items: center">
                <div style="margin-right: 10px">
                  <div
                    v-if="
                      vendedor &&
                      vendedor.metas &&
                      vendedor.metas.length &&
                      vendedor.metas.find((x) => x.meta_key === 'photo') &&
                      vendedor.metas.find((x) => x.meta_key === 'photo').meta_value
                    "
                  >
                    <img
                      style="margin: 0 !important"
                      class="seller-photo"
                      :src="vendedor.metas.find((x) => x.meta_key === 'photo').meta_value"
                      alt=""
                    />
                  </div>
                  <div
                    v-else-if="lead && lead.user_id"
                    class="seller-no-ft"
                    style="margin: 0 !important"
                  >
                    <h3 style="text-align: center">
                      {{ getMetaInitial(lead.user_id) }}
                    </h3>
                  </div>
                  <img
                    style="margin: 0 !important"
                    v-else
                    class="user-icon"
                    src="@/assets/icons/no-seller.svg"
                    alt=""
                  />
                </div>
                <span class="sellers-name">
                  <label style="margin: 0 !important; cursor: pointer" for="">Vendedor</label>
                  {{
                    vendedor
                      ? `${vendedor.first_name ? vendedor.first_name : ""} ${
                          vendedor.last_name ? vendedor.last_name : ""
                        }`
                      : "Selecione um vendedor"
                  }}
                </span>
              </div>
              <div
                v-if="currentModal == 'seller'"
                class="drop-top"
                id="tags-all"
                @click.stop="&quot;&quot;;"
              >
                <img src="@/assets/img/icons/search.svg" class="Pointer searchIcon" />
                <input type="text" v-model="sellerSearch" />
                <div v-if="sellers.length">
                  <div
                    class="sub-card cursor-pointer vendedores"
                    :style="
                      (sellerWord == 'vendedor' && !vendedor) ||
                      (sellerWord != 'vendedor' && !pre_vendedor)
                        ? 'cursor: default; background-color: #f1f1f1 !important;'
                        : ''
                    "
                    style="padding: 5px 10px !important"
                    @click.stop="
                      (sellerWord == 'vendedor' && !vendedor) ||
                      (sellerWord != 'vendedor' && !pre_vendedor)
                        ? ''
                        : removerVendedor(sellerWord == 'vendedor' ? 'user' : 'pre_user')
                    "
                  >
                    <div style="display: flex; align-items: center">
                      <div style="margin-right: 10px">
                        <img
                          style="margin: 0 !important"
                          class="user-icon"
                          src="@/assets/icons/no-seller.svg"
                          alt=""
                        />
                      </div>
                      <span>
                        <label
                          :style="
                            (sellerWord == 'vendedor' && !vendedor) ||
                            (sellerWord != 'vendedor' && !pre_vendedor)
                              ? 'cursor: default;'
                              : 'cursor: pointer;'
                          "
                          style="margin: 0 !important"
                          for=""
                          >Sem {{ sellerWord }}</label
                        >
                        Sem {{ sellerWord }}
                      </span>
                    </div>
                  </div>
                  <div
                    v-for="(item, index) in filterSearchSellers"
                    :key="index"
                    class="sub-card cursor-pointer vendedores"
                    style="padding: 5px 10px !important"
                    :style="
                      (sellerWord == 'vendedor' && vendedor && item.id == vendedor.id) ||
                      (sellerWord != 'vendedor' && pre_vendedor && item.id == pre_vendedor.id)
                        ? 'cursor: default; background-color: #f1f1f1 !important;'
                        : ''
                    "
                    @click.stop="
                      (sellerWord == 'vendedor' && vendedor && item.id == vendedor.id) ||
                      (sellerWord != 'vendedor' && pre_vendedor && item.id == pre_vendedor.id)
                        ? ''
                        : editVendedor(item.id, sellerWord == 'vendedor' ? 'user' : 'pre_user')
                    "
                  >
                    <div style="display: flex; align-items: center">
                      <div style="margin-right: 10px">
                        <div
                          v-if="
                            item &&
                            item.metas &&
                            item.metas.length &&
                            item.metas.find((x) => x.meta_key === 'photo') &&
                            item.metas.find((x) => x.meta_key === 'photo').meta_value
                          "
                        >
                          <img
                            style="margin: 0 !important"
                            class="seller-photo"
                            :src="item.metas.find((x) => x.meta_key === 'photo').meta_value"
                            alt=""
                          />
                        </div>
                        <div v-else class="seller-no-ft" style="margin: 0 !important">
                          <h3 style="text-align: center">
                            {{ getMetaInitial(item.id) }}
                          </h3>
                        </div>
                      </div>
                      <span class="sellers-name">
                        <label
                          :style="
                            (sellerWord == 'vendedor' && vendedor && item.id == vendedor.id) ||
                            (sellerWord != 'vendedor' && pre_vendedor && item.id == pre_vendedor.id)
                              ? 'cursor: default;'
                              : 'cursor: pointer;'
                          "
                          style="margin: 0 !important"
                          for=""
                          >{{ item.first_name ? item.first_name : "" }}
                          {{ item.last_name ? item.last_name : "" }}</label
                        >
                        {{ item.email }}
                      </span>
                    </div>
                  </div>
                </div>
                <span v-else>Nenhum vendedor encontrado</span>
              </div>
            </div>
          </div>
        </div>
        <b-tabs
          style="
            margin-top: 10px !important;
            margin-right: 20px !important;
            width: -webkit-fill-available;
            height: 100%;
          "
          id="visualizar-lead-tab"
          nav-class="TabHeader no-border"
          align="center"
          no-fade
          active-nav-item-class="TabHeader-- tab-containeractive"
          :no-nav-style="true"
          v-model="paginacao"
          v-if="!loading && lead"
          pills
        >
          <b-tab @click="paginacao = 0" key="0">
            <template #title>
              <img
                v-if="paginacao != 0"
                src="@/assets/img/icons/userCRM.svg"
                class="img-tab-title"
                alt="bag"
              />
              <img
                v-else
                src="@/assets/img/icons-active/user.svg"
                alt="bag"
                class="img-tab-title"
              />
              <strong
                class="tab-title"
                :class="paginacao == 0 ? 'active' : 'non-active'"
                :style="paginacao == 0 ? 'max-width: 90px;' : ''"
                >Dados</strong
              >
            </template>
            <div class="grid2" style="grid-template-columns: 1fr 350px">
              <div>
                <div class="lead-CRM" v-show="config.hasCRM">
                  <h4 class="title">
                    CRM<span style="text-transform: lowercase; color: inherit; display: inline"
                      >s</span
                    >
                    relacionados
                    <img
                      style="display: inline-block; height: 16px; margin: -4px 3px 0"
                      v-b-tooltip.hover
                      src="@/assets/img/icons/info.svg"
                      alt="informação: máximo de 3 CRMs"
                      title="Limite de 3 CRMs"
                    />
                  </h4>
                  <b-form-group
                    v-if="`${tags_props.length && !loadingCRM}` || inlist"
                    v-show="pipelines.length"
                    class="mt-3"
                  >
                    <div class="products-cards mb-3 crm-cards">
                      <div
                        v-for="(item, index) in pipelines_lead"
                        :key="index"
                        :class="[
                          'sub-card mb-0',
                          !currentSituation(item) ? '' : currentSituation(item),
                        ]"
                      >
                        <div
                          class="product-card"
                          style="grid-template-columns: calc(100% - 35px) 20px"
                        >
                          <div id="crm-infos" class="product-infos">
                            <p v-b-tooltip.hover :title="item.title" style="max-width: 180px">
                              {{ item.title }}
                            </p>
                            <span
                              v-if="item.tags.find((tag) => tag.isSelected == true)"
                              :class="`${
                                item.tags.find((tag) => tag.isSelected == true).color
                              }_color lead-board`"
                            >
                              Board:
                              {{ item.tags.find((tag) => tag.isSelected == true).name }}
                            </span>
                            <span v-else class="default_color lead-board">
                              Board não encontrado
                            </span>
                            <!-- LOSS AND GAIN CONTAINER INIT -->
                            <div
                              v-if="
                                currentSituation(item) &&
                                item.tags[item.tags.length - 1].id ==
                                  item.tags.find((tag) => tag.isSelected == true).id
                              "
                            >
                              <span
                                v-if="currentSituation(item) === 'gain'"
                                class="reason cursor"
                                style="color: var(--greenn); font-weight: 600"
                                v-b-tooltip.hover
                                :title="currentSituationTitle(item)"
                                @click="reopenSituation(item.id)"
                              >
                                GANHO
                              </span>

                              <span
                                v-else-if="currentSituation(item) === 'open'"
                                class="reason cursor"
                                style="color: #00925c; font-weight: 600"
                                @click="decideSituation(item.id)"
                              >
                                ABERTO
                              </span>

                              <span
                                v-else-if="currentSituation(item) === 'loss'"
                                class="reason cursor"
                                style="color: var(--red); font-weight: 600"
                                v-b-tooltip.hover
                                :title="currentSituationTitle(item)"
                                @click="reopenSituation(item.id)"
                              >
                                PERDIDO
                              </span>
                            </div>

                            <span
                              v-else-if="
                                item.tags[item.tags.length - 1]?.id ==
                                item.tags.find((tag) => tag.isSelected == true)?.id
                              "
                              class="reason cursor"
                              style="color: #00925c; font-weight: 600"
                              @click="decideSituation(item.id)"
                            >
                              ABERTO
                            </span>
                          </div>
                          <div style="position: relative">
                            <img
                              @click="
                                removeLead(
                                  item.id,
                                  item.tags.find((tag) => tag.isSelected == true).id
                                )
                              "
                              src="@/assets/img/icons/trash2.svg"
                              class="x cursor"
                              style="margin: 0 0px 10px 0 !important; padding: 0 3px 0 0 !important"
                            />
                            <img
                              style="
                                margin: 0 !important;
                                padding: 0 !important;
                                height: 16px !important;
                              "
                              src="@/assets/icons/edit.svg"
                              class="cursor x"
                              @click.stop="
                                currentModal == `crm-collapse-${item.id}`
                                  ? (currentModal = '')
                                  : (currentModal = `crm-collapse-${item.id}`)
                              "
                            />
                            <div
                              v-if="currentModal == `crm-collapse-${item.id}`"
                              class="drop-bottom drop-right"
                              style="text-align: right; width: fit-content; max-width: 250px"
                              @click.stop="&quot;&quot;;"
                            >
                              <span
                                :class="`${tag.color}_color lead-board cursor`"
                                v-for="tag in item.tags.filter((x) => x.isSelected == false)"
                                @click.stop="
                                  attBoard(
                                    item.id,
                                    tag.id,
                                    false,
                                    item.tags.find((tag) => tag.isSelected == true).id
                                  )
                                "
                                >{{ tag.name }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="sub-card mb-0"
                        v-show="
                          pipelines_lead.length < 3 && pipelines_lead.length != pipelines.length
                        "
                      >
                        <div class="product-card" style="width: 100%; display: flex">
                          <div
                            @click.stop="
                              currentModal == 'crm' ? (currentModal = '') : (currentModal = 'crm')
                            "
                            class="product-photo cursor"
                            style="background-color: var(--greenn-transparent)"
                          >
                            <span style="font-size: 45px; font-weight: unset; letter-spacing: unset"
                              >+</span
                            >
                          </div>
                          <div
                            class="product-infos cursor"
                            @click.stop="
                              currentModal == 'crm' ? (currentModal = '') : (currentModal = 'crm')
                            "
                          >
                            <p>Adicionar CRM</p>
                            <span> Clique para selecionar </span>
                          </div>
                        </div>
                        <div
                          v-if="currentModal == 'crm'"
                          class="drop-bottom"
                          @click.stop="&quot;&quot;;"
                        >
                          <p style="color: var(--grey02)" class="mt-2">Selecione o CRM</p>

                          <BaseSelect
                            :selectModel="pipeline"
                            id="ajax"
                            trackname="titulo"
                            track-by="id"
                            placeholder="Pesquise o CRM"
                            selectLabel=""
                            :allow-empty="false"
                            deselectLabel="✔️"
                            selectedLabel="✔️"
                            open-direction="bottom"
                            @select="(pipeline = $event), selectedPipe(pipeline)"
                            :array="
                              pipelines.filter((pipe) =>
                                pipelines_lead.every((item) => item.id != pipe.id)
                              )
                            "
                            :multiple="false"
                            :taggable="false"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :show-no-results="true"
                            :hide-selected="false"
                            @callback=""
                            :watch="true"
                          >
                            <span slot="noOptions">Pesquise pelo CRM</span>
                            <span slot="noResult">Oops! Nenhum CRM encontrado.</span>
                          </BaseSelect>

                          <p style="color: var(--grey02)" class="mt-2">Selecione o board</p>
                          <div v-if="pipeline && pipeline.id">
                            <span
                              :class="`${
                                tag ? (tag.color ? tag.color : 'defaulf') : 'default'
                              }_color lead-board mt-1 cursor`"
                              v-for="tag in pipeline.tags.filter((x) => x != null)"
                              @click.stop="attBoard(pipeline.id, tag.id ? tag.id : tag, true)"
                              >{{ tag.name ? tag.name : tag }}</span
                            >
                          </div>
                          <div v-else>
                            <span
                              class="lead-board mt-1"
                              style="background-color: var(--gray05)"
                              v-for="n in 8"
                              >...</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-form-group>
                  <div v-if="loadingCRM" class="products-cards mb-4">
                    <div
                      v-for="n in 2"
                      class="sub-card loading"
                      style="height: 84px; margin-bottom: 0"
                    ></div>
                  </div>
                  <component
                    v-if="!pipelines.length && !loadingCRM"
                    :is="`${!isIframe ? 'router-link' : 'a'}`"
                    :to="{ name: 'CRM' }"
                  >
                    <div class="box-total mt-2 mb-4 cursor">
                      <div class="total-vendas">
                        <p class="info" style="text-decoration: none !important">
                          Nenhum CRM cadastrado no painel
                        </p>
                      </div>
                    </div>
                  </component>
                </div>
                <div class="lead-comercial">
                  <h4 class="title">Produtos relacionados</h4>
                  <div class="products-cards mb-3" v-show="products.length">
                    <div v-for="(item, index) in lead_products" :key="index" class="sub-card mb-0">
                      <div class="product-card">
                        <div
                          class="product-photo"
                          :style="
                            item.product && item.product.product_photo
                              ? `background-image: url(${item.product.product_photo});`
                              : 'background-color: var(--greenn-transparent);'
                          "
                        >
                          <span v-if="item.product && !item.product.product_photo">{{
                            initialsProduct(item.product.name)
                          }}</span>

                          <span
                            v-b-tooltip.hover
                            :title="`${item.quantity.toLocaleString('pt-BR')} ${
                              item.quantiy === 1 ? 'cópia' : 'cópias'
                            }`"
                            v-if="item.quantity > 1"
                            class="product-quantity"
                            >x{{ item.quantity }}</span
                          >
                        </div>
                        <div class="product-infos">
                          <p>
                            {{
                              item.product && item.product.name
                                ? item.product.name
                                : "Produto desconhecido"
                            }}
                          </p>
                          <span> R${{ item.net_value }} ({{ item.discount }}% de desconto) </span>
                        </div>
                        <div style="position: relative">
                          <img
                            @click.stop="desassociarProduto(item.product_id)"
                            src="@/assets/img/icons/trash2.svg"
                            class="x cursor"
                            style="margin: 0 0px 10px 0 !important; padding: 0 3px 0 0 !important"
                          />
                          <img
                            style="
                              margin: 0 !important;
                              padding: 0 !important;
                              height: 16px !important;
                            "
                            src="@/assets/icons/edit.svg"
                            class="cursor x"
                            @click.stop="
                              (editProduct.discount = item.discount),
                                (editProduct.finalPrice = item.net_value),
                                (editProduct.price = item.product.product_value),
                                (editProduct.quantity = item.quantity),
                                (editProduct.quantityBeforeMutate = item.quantity);
                              currentModal == `product-collapse-${item.product_id}`
                                ? (currentModal = '')
                                : (currentModal = `product-collapse-${item.product_id}`);
                            "
                          />
                          <div
                            v-if="currentModal == `product-collapse-${item.product_id}`"
                            class="drop-bottom drop-right"
                            @click.stop="&quot;&quot;;"
                          >
                            <div
                              class="mt-2"
                              style="
                                display: grid;
                                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
                                gap: 5px;
                              "
                            >
                              <div>
                                <p style="color: var(--grey02)">
                                  Desconto ({{ editProduct.discount }}% -
                                  {{
                                    Math.round(
                                      (editProduct.price * editProduct.quantity -
                                        editProduct.finalPrice) *
                                        100
                                    ) / 100
                                  }}
                                  reais)
                                </p>
                                <input
                                  style="margin-bottom: 0 !important"
                                  type="range"
                                  max="100"
                                  min="0"
                                  step="1"
                                  v-model="editProduct.discount"
                                  @change="ajustValuesProduct(true)"
                                />
                              </div>
                              <div>
                                <p style="color: var(--grey02)">Quantidade</p>
                                <input
                                  @change="ajustValuesProduct(true)"
                                  v-model="editProduct.quantity"
                                  type="number"
                                  name="quantity"
                                  id="quantity"
                                />
                              </div>

                              <div>
                                <p style="color: var(--grey02)">Valor final</p>
                                <money
                                  id="static_value"
                                  ref="product"
                                  v-model="editProduct.finalPrice"
                                  v-bind="money"
                                  class="w-100"
                                ></money>
                              </div>
                            </div>
                            <button
                              class="btn-follow w-100"
                              @click="salvarDesconto(item), (currentModal = '')"
                            >
                              Atualizar produto
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="sub-card cursor mb-0"
                      v-show="showContainerAddProduct && filteredProducts.length"
                    >
                      <div
                        class="product-card"
                        style="width: 100%"
                        @click.stop="
                          currentModal == 'product'
                            ? (currentModal = '')
                            : (currentModal = 'product'),
                            (selectProduct = null),
                            (valorPorcentagem = 0),
                            (quantity = 1)
                        "
                      >
                        <div
                          class="product-photo"
                          style="background-color: var(--greenn-transparent)"
                        >
                          <span style="font-size: 45px; font-weight: unset; letter-spacing: unset"
                            >+</span
                          >
                        </div>
                        <div class="product-infos">
                          <p>Associar produto</p>
                          <span> Clique para selecionar </span>
                        </div>
                      </div>
                      <div
                        v-show="currentModal == 'product'"
                        class="drop-bottom"
                        @click.stop="&quot;&quot;;"
                      >
                        <BaseSelect
                          open-direction="bottom"
                          :selectModel="selectProduct"
                          placeholder="Selecione um produto"
                          trackname="name"
                          specificType="products"
                          track-by="id"
                          :array="filteredProducts"
                          :multiple="false"
                          :taggable="true"
                          class=""
                          selectLabel=""
                          deselectLabel="✔️"
                          selectedLabel="✔️"
                          :allow-empty="false"
                          :timeout="500"
                          @callback=""
                          :watch="true"
                          @change="(selectProduct = $event), changeSelectedProduct()"
                          ><span slot="noResult">Nenhum produto encontrado.</span>
                        </BaseSelect>
                        <div
                          class="mt-2"
                          style="
                            display: grid;
                            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
                            gap: 5px;
                          "
                        >
                          <div>
                            <p style="color: var(--grey02)">
                              Desconto ({{ selectProduct ? valorPorcentagem : 0 }}% -
                              {{
                                selectProduct
                                  ? Math.round(
                                      (selectProduct.product_value * quantity - valorLiquido) * 100
                                    ) / 100
                                  : 0
                              }}
                              reais)
                            </p>
                            <input
                              style="margin-bottom: 0 !important"
                              type="range"
                              max="100"
                              min="0"
                              step="1"
                              v-model="valorPorcentagem"
                              @change="ajustValues(true)"
                            />
                          </div>

                          <div>
                            <p style="color: var(--grey02)">Quantidade</p>
                            <input
                              :disabled="!selectProduct"
                              @change="ajustValues(true)"
                              v-model="quantity"
                              type="number"
                              name="quantity"
                              id="quantity"
                            />
                          </div>

                          <div>
                            <p style="color: var(--grey02)">Valor final</p>
                            <money
                              id="static_value"
                              ref="product"
                              v-model="valorLiquido"
                              v-bind="money"
                              class="w-100"
                            >
                            </money>
                          </div>
                        </div>
                        <button
                          class="btn-follow w-100"
                          @click="salvarDesconto(selectProduct), (currentModal = '')"
                        >
                          Associar produto ao lead
                        </button>
                      </div>
                    </div>
                  </div>
                  <component
                    :is="`${!isIframe ? 'router-link' : 'a'}`"
                    v-show="!products.length"
                    :to="{ name: 'ConfiguracoesSistema' }"
                  >
                    <div class="box-total mt-2 mb-4 cursor">
                      <div class="total-vendas">
                        <p class="info">Nenhum produto cadastrado no painel</p>
                      </div>
                    </div>
                  </component>
                </div>
                <div>
                  <h4 class="title">Campos adicionais</h4>
                  <div class="products-cards mb-3 crm-cards">
                    <div
                      v-for="(item, index) in lead.metas.filter(
                        (meta) =>
                          meta.meta_key != 'photo-lead' &&
                          meta.meta_key != 'tag_ids' &&
                          meta.meta_key != 'pipeline_view'
                      )"
                      :key="index"
                      class="sub-card"
                      ref="subCard"
                    >
                      <ProductCard
                        :item="item"
                        :index="index"
                        @deleteAdd="deleteAdd"
                        @saveAdd="saveAdd"
                        :currentModal="currentModal"
                        @updateModal="currentModal = $event"
                        :widthMax="widthMax"
                      />
                    </div>
                    <div class="sub-card mb-0">
                      <div
                        class="product-card"
                        style="width: 100%"
                        @click.stop="
                          (editAdicional.name = ''),
                            (editAdicional.value = ''),
                            currentModal == 'adicional'
                              ? (currentModal = '')
                              : (currentModal = 'adicional')
                        "
                      >
                        <div
                          class="product-photo cursor"
                          style="background-color: var(--greenn-transparent)"
                        >
                          <span style="font-size: 45px; font-weight: unset; letter-spacing: unset"
                            >+</span
                          >
                        </div>
                        <div class="product-infos cursor">
                          <p>Criar campo adicional</p>
                          <span> Clique para abrir </span>
                        </div>
                      </div>
                      <div
                        v-if="currentModal == 'adicional'"
                        class="drop-top"
                        @click.stop="&quot;&quot;;"
                      >
                        <input
                          class="mt-2"
                          type="text"
                          placeholder="Nome do campo"
                          v-model="editAdicional.name"
                        />
                        <input
                          type="text"
                          placeholder="Valor do campo"
                          v-model="editAdicional.value"
                        />
                        <div
                          @click.stop="createAdd(), (showAddAdicional = false), (currentModal = '')"
                          class="cursor btn-follow w-100"
                          style="text-align: center"
                        >
                          Criar campo adicional
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lead-notes">
                <div id="add-nota">
                  <b-form-textarea
                    placeholder="Adicione uma nota"
                    v-model="nota"
                    id="nota"
                    rows="1"
                    max-rows="8"
                    @keydown.enter.prevent="addNota"
                  ></b-form-textarea>
                  <span class="cursor" @click="addNota"> + </span>
                  <p @click="openModalFile()" class="clipe">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#4ea934"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-paperclip"
                    >
                      <path
                        d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"
                      ></path>
                    </svg>
                  </p>
                </div>
                <div
                  v-if="lead && lead.notes"
                  style="display: flex; flex-direction: column-reverse"
                >
                  <div v-for="(item, index) in lead.notes" :key="index" class="body-nota">
                    <div class="lead-note">
                      <div
                        v-if="
                          item.content.match(/.pdf/) &&
                          item.content.match(/gdigital.s3.amazonaws.com/)
                        "
                        style="background-color: white"
                        class="sub-card d-flex justify-content-between"
                      >
                        <div class="d-flex g-2">
                          <img src="@/assets/icons/file.svg" alt="file" />
                          <p class="pl-2 pt-1">Documento PDF</p>
                        </div>
                        <a class="pt-1" :href="item.content" target="_blank">
                          <img src="@/assets/icons/eye_green.svg" alt="eye_green" />
                        </a>
                      </div>
                      <div
                        v-else-if="
                          item.content.match(/.docx/) &&
                          item.content.match(/gdigital.s3.amazonaws.com/)
                        "
                        style="background-color: white"
                        class="sub-card d-flex justify-content-between"
                      >
                        <div class="d-flex g-2">
                          <img src="@/assets/icons/file.svg" alt="file" />
                          <p class="pl-2 pt-1">Documento DOCX</p>
                        </div>
                        <a class="pt-1" :href="item.content">
                          <img src="@/assets/icons/download.svg" alt="eye_green" />
                        </a>
                      </div>
                      <p
                        v-else-if="
                          item.content.match(/.webp/) &&
                          item.content.match(/gdigital.s3.amazonaws.com/)
                        "
                        @click="openImg(item.content)"
                      >
                        <img
                          style="
                            width: 100%;
                            border-radius: 10px;
                            cursor: pointer;
                            object-fit: cover;
                            max-height: 250px;
                          "
                          :src="item.content"
                          alt="IMG"
                        />
                      </p>
                      <p v-else>
                        {{ item.content }}
                      </p>
                      <p v-if="item && item.created_at" class="lead-note-date gray ml-2">
                        {{ getUserInfo(item.user_id).first_name }}
                        {{ getUserInfo(item.user_id).last_name }} -
                        {{ item.updated_at | datetimem }}
                      </p>
                      <!-- <input :ref="'note' + index" v-if="notaSelected.id == index" @blur="editNota(item, index)" type="text"
                    name="nota" id="nota" v-model="item.content" /> -->
                      <div class="delete-note-ghost" @click="deletarNota(item.id)"></div>
                    </div>
                  </div>
                </div>

                <div v-if="lead.notes && lead.notes.length == 0" class="box-total">
                  <div class="total-vendas">
                    <p class="info">Nenhuma nota cadastrada</p>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab @click.once="openTab(1)" @click="paginacao = 1" key="1">
            <template #title>
              <img
                v-if="paginacao != 1"
                src="@/assets/img/icons/clockCRM.svg"
                class="img-tab-title"
                alt="timeline"
              />
              <img
                v-else
                src="@/assets/img/icons-active/clock.svg"
                class="img-tab-title"
                alt="timeline"
              />
              <strong
                class="tab-title"
                :class="paginacao == 1 ? 'active' : 'non-active'"
                :style="paginacao == 1 ? 'max-width: 80px;' : ''"
                >Histórico</strong
              >
            </template>
            <div class="grid2" style="grid-template-columns: 1fr 350px">
              <div class="items time-scroll" id="infinite-list">
                <h4 class="title">Histórico de ações</h4>
                <div class="time-line" v-if="timeline.length">
                  <historyCard
                    v-for="(item, index) in timeline"
                    :key="index"
                    :action="item"
                    :users="sellers"
                  >
                  </historyCard>
                </div>
                <div v-else class="box-total">
                  <div class="total-vendas">
                    <p class="info">O lead ainda não possui um histórico de ações</p>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  listLogMessage &&
                  listLogMessage.length > 0 &&
                  config.hasMessageHistoric &&
                  !loadingHist
                "
                style="
                  background-color: var(--greenn2);
                  border-radius: 0 10px 10px 0;
                  padding: 0 !important;
                  overflow-y: hidden;
                "
              >
                <div
                  class="log-header user-header"
                  :style="
                    level !== 'seller' && level !== 'gerent_seller'
                      ? ''
                      : 'cursor: unset !important;'
                  "
                  @click.stop="
                    level !== 'seller' && level !== 'gerent_seller'
                      ? (sellerSelect = !sellerSelect)
                      : ''
                  "
                  v-if="vendedorHist && vendedorHist.metas"
                >
                  <img
                    v-if="vendedorHist.metas.find((meta) => meta.meta_key == 'photo')"
                    :src="vendedorHist.metas.find((meta) => meta.meta_key == 'photo').meta_value"
                    alt=""
                  />
                  <div v-else class="initial small all-white">
                    {{ getMetaInitial(vendedorHist.id) }}
                  </div>
                  <div class="user-info">
                    <span class="name-hist"
                      >{{ vendedorHist.first_name }} {{ vendedorHist.last_name }}</span
                    >
                    <span class="email-hist">{{ vendedorHist.email }}</span>
                  </div>
                  <div
                    v-if="level !== 'seller' && level !== 'gerent_seller'"
                    :style="sellerSelect ? 'transform: rotate(180deg);' : ''"
                    class="select"
                  />
                  <Transition
                    v-if="level !== 'seller' && level !== 'gerent_seller'"
                    name="AnimationRight"
                    v-show="sellerSelect"
                  >
                    <div @click.stop="" class="seller-select" v-if="sellerSelect">
                      <div class="lead-search2">
                        <img src="@/assets/img/icons/search.svg" class="Pointer searchIcon" />
                        <input v-focus type="text" v-model="sellerSearchHist" />
                      </div>
                      <div v-if="sellers.length">
                        <div
                          style="padding: 5px 10px !important"
                          v-for="(item, index) in filterSellerHist"
                          :key="index"
                          class="sub-card-hist vendedores"
                          :style="
                            item.id == vendedorHist.id
                              ? 'cursor: default; background-color: #f1f1f1 !important;'
                              : ''
                          "
                          @click.stop="(vendedorHist = item), (sellerSelect = !sellerSelect)"
                        >
                          <div style="display: flex; align-items: center">
                            <div style="margin-right: 10px">
                              <div v-if="item.metas.find((x) => x.meta_key === 'photo')">
                                <img
                                  style="margin: 0 !important"
                                  class="seller-photo"
                                  :src="item.metas.find((x) => x.meta_key === 'photo').meta_value"
                                  alt=""
                                />
                              </div>
                              <div v-else class="initial small" style="margin: 0 !important">
                                {{ getMetaInitial(item.id) }}
                              </div>
                            </div>
                            <span class="sellers-name">
                              <label
                                :style="
                                  item.id == vendedorHist.id
                                    ? 'cursor: default;'
                                    : 'cursor: pointer;'
                                "
                                style="margin: 0 !important"
                                for=""
                                >{{ item.first_name ? item.first_name : "" }}
                                {{ item.last_name ? item.last_name : "" }}</label
                              >
                              {{ item.email }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <span v-else>Nenhum vendedor encontrado</span>
                    </div>
                  </Transition>
                </div>
                <WhatsAppMessages
                  v-if="!loadingHist"
                  ref="WhatsAppMessages"
                  fromLead="true"
                  style="width: 100%; height: calc(100% - 55px) !important"
                  :lead="lead"
                  :seller="vendedorHist"
                />
              </div>
              <div v-else style="background-color: var(--greenn2); border-radius: 0 10px 10px 0">
                <h4 class="title">Histórico de mensagens</h4>
                <div
                  v-if="listLogMessage && listLogMessage.length == 0 && config.hasMessageHistoric"
                  class="box-total"
                >
                  <div class="total-vendas">
                    <p class="info">Nenhuma mensagem</p>
                  </div>
                </div>
                <div v-else-if="!config.hasMessageHistoric" class="box-total">
                  <div class="total-vendas">
                    <p class="info">Funcionalidade desabilitada</p>
                  </div>
                </div>
                <div v-else class="box-total">
                  <div class="total-vendas" style="gap: 10px">
                    <img
                      style="margin-left: -10px"
                      src="@/assets/img/icons/lock.svg"
                      class="img-tab-title"
                      alt="mensages"
                    />
                    <p class="info">Essa funcionalidade não está incluída em seu plano</p>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab @click.once="openTab(2)" @click="paginacao = 2" key="2">
            <template #title>
              <img
                v-if="paginacao != 2"
                src="@/assets/img/icons/mensage.svg"
                class="img-tab-title"
                alt="mensages"
              />
              <img
                v-else
                src="@/assets/img/icons-active/mensage.svg"
                class="img-tab-title"
                alt="mensages"
              />
              <strong
                class="tab-title"
                :class="paginacao == 2 ? 'active' : 'non-active'"
                :style="paginacao == 2 ? 'max-width: 85px;' : ''"
                >Mensagens</strong
              >
            </template>
            <div class="grid2" style="grid-template-columns: 1fr 350px">
              <div>
                <h4 class="title">
                  Mensagens
                  <img
                    style="display: inline-block; height: 16px; margin: -4px 3px 0"
                    v-b-tooltip.hover
                    src="@/assets/img/icons/info.svg"
                    alt="informação: máximo de 3 CRMs"
                    title="É necessário ter o G Whats para realizar o envio das mensagens"
                  />
                </h4>
                <div class="mensagem">
                  <div
                    v-show="!loadingMensagem"
                    v-for="(item, index) in wpp"
                    :key="index"
                    class="sub-card"
                    style="display: flex; flex-direction: column; gap: 10px"
                  >
                    <div class="message-header">
                      <div style="display: flex; gap: 10px">
                        <p :class="item.status" class="message-status">
                          Status:
                          {{ returnStatusMessage(!verifyDate(item) ? item.status : "queue") }}
                        </p>
                        <p :class="item.status" class="message-status mt-0">
                          {{ item.date_added | datetimem }}
                        </p>
                      </div>
                      <img
                        style="width: 9px; height: 9px"
                        class="label-icon cursor"
                        src="@/assets/img/icons/x.svg"
                        alt="icon-label"
                        v-if="item.status === 'preparing' && !verifyDate(item)"
                        @click="cancelMessage(item.id, index)"
                      />
                      <img
                        style="width: 15px; height: 15px"
                        class="label-icon cursor"
                        src="@/assets/img/icons/refresh-ccw-black.svg"
                        alt="icon-label"
                        v-if="item.status === 'error'"
                        @click="resendMessage(item.id)"
                      />
                    </div>
                    <p style="color: var(--gray03)">{{ item.message }}</p>
                  </div>
                  <div
                    v-show="loadingMensagem"
                    style="height: 90px !important"
                    class="sub-card loading"
                    v-for="(item, index) in 10"
                  ></div>
                  <div v-if="wpp && wpp.length == 0" class="box-total">
                    <div class="total-vendas">
                      <p class="info">Nenhuma mensagem agendada</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style="background-color: var(--greenn2); border-radius: 0 10px 10px 0">
                <h4 class="title">Nova mensagem</h4>
                <b-form-textarea
                  id="textarea"
                  :placeholder="`Olá, {{nome}}! Tudo bem?`"
                  v-model="mensagem"
                  no-resize
                  style="height: 200px !important; margin-bottom: 0.75rem !important"
                ></b-form-textarea>
                <date-range-picker
                  style="border-radius: 10px !important; width: 100%"
                  ref="picker"
                  opens="center"
                  :locale-data="localeData"
                  :min-date="currentDate"
                  :showWeekNumbers="false"
                  :showDropdowns="true"
                  :autoApply="false"
                  :ranges="false"
                  :timePicker24Hour="true"
                  :timePicker="true"
                  v-model="dateRange2"
                  :single-date-picker="true"
                  v-if="renderDate"
                >
                  <template v-if="!dateRange2.startDate" v-slot:input>
                    <div
                      style="
                        display: flex;
                        width: 100%;
                        height: 100%;
                        align-items: center;
                        gap: 15px;
                      "
                    >
                      <i class="fa fa-calendar"></i>
                      <p style="margin-bottom: 0 !important">Selecione a data</p>
                    </div>
                  </template>
                </date-range-picker>
                <input type="text" class="w-100" v-else style="margin-bottom: 5px !important" />
                <div @click="agendarMensagem" class="btn-follow w-100 mt-2 cursor">
                  Agendar mensagem
                </div>
              </div>
            </div>
          </b-tab>

          <b-tab @click.once="openTab(3)" @click="paginacao = 3" key="3">
            <template #title>
              <img
                v-if="paginacao != 3"
                src="@/assets/img/icons/check-square.svg"
                alt="comerce"
                class="img-tab-title"
              />
              <img
                v-else
                src="@/assets/img/icons-active/check-square.svg"
                class="img-tab-title"
                alt="comerce"
              />
              <strong
                class="tab-title"
                :class="paginacao == 3 ? 'active' : 'non-active'"
                :style="paginacao == 3 ? 'max-width: 78px;' : ''"
                >Atividades</strong
              >
            </template>
            <div v-if="!disappear" class="grid2" style="grid-template-columns: 1fr 350px">
              <div v-if="!recursos.ntask" class="__blocked">
                <svg
                  fill="#4ea934"
                  height="150px"
                  width="150px"
                  version="1.1"
                  viewBox="0 0 330 330"
                >
                  <g stroke-width="0"></g>
                  <g stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <g id="XMLID_509_">
                      <path
                        id="XMLID_510_"
                        d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15 s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25 C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"
                      ></path>
                    </g>
                  </g>
                </svg>
                <span style="color: inherit" class="title">Acesso bloqueado</span>
                <span style="font-size: 14px; color: inherit; opacity: 0.7"
                  >Esse recurso ainda não está incluso em seu plano,<br />
                  fale com nosso time comercial para mais informações.</span
                >
                <button @click="upgradePlan()">Fazer upgrade</button>
              </div>

              <div
                @mouseenter.once="recursos.ntask ? '' : (disappear = true)"
                style="padding: 30px 35px"
              >
                <div style="height: 100%" v-if="activities.length">
                  <div class="activities-line">
                    <div
                      v-for="(item, index) in activeStepItem"
                      style="display: flex"
                      @click="currentStep = index"
                    >
                      <div>
                        <div
                          class="activity-dot"
                          :class="{
                            complete: index < complete,
                            progress: index == complete,
                            incomplete: index > complete,
                            cursor: index == complete || index == complete - 1,
                          }"
                          @click.stop="
                            index == complete
                              ? checkDoneActivitie(activeStepItem[index].id, false, index)
                              : index == complete - 1
                              ? checkDoneActivitie(activeStepItem[index].id, true, index)
                              : ''
                          "
                        ></div>
                        <div
                          class="line"
                          :class="{
                            'complete-line': index + 1 < complete,
                            'progress-line': index + 1 == complete,
                            'incomplete-line': index + 1 > complete,
                          }"
                          v-if="activeStepItem.length != index + 1"
                        ></div>
                      </div>
                      <div class="activity-content">
                        <h4 class="title">{{ index + 1 }} - {{ item.title }}</h4>
                        <div class="sub-card" :class="currentStep == index ? 'active-card' : ''">
                          <div v-if="item.type != 'email'">
                            {{ filterName(item.body) }}
                          </div>
                          <div style="position: relative" v-else>
                            <div
                              style="
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: 1;
                              "
                            ></div>
                            <div v-html="item.body" style="zoom: 0.5"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <component
                  :is="`${!isIframe ? 'router-link' : 'a'}`"
                  v-else
                  :to="{ name: 'ConfiguracoesSistema' }"
                  style="padding: 0 25px !important"
                >
                  <div
                    class="box-total cursor"
                    style="
                      grid-column-start: 1;
                      grid-column-end: 3;
                      height: 83px;
                      width: -webkit-fill-available;
                      margin: 20px;
                    "
                  >
                    <div class="total-vendas">
                      <p class="info">Nenhuma atividade configurada no sistema</p>
                    </div>
                  </div>
                </component>
              </div>
              <div
                @mouseenter.once="recursos.ntask ? '' : (disappear = true)"
                style="
                  background-color: var(--greenn2);
                  border-radius: 0 10px 10px 0;
                  overflow-x: hidden;
                "
              >
                <div v-if="activities.length">
                  <multiselect
                    v-model="activeCategory"
                    id="ajax"
                    label="title"
                    track-by="title"
                    placeholder=""
                    selectLabel=""
                    :allow-empty="false"
                    deselectLabel="✔️"
                    selectedLabel="✔️"
                    open-direction="bottom"
                    :options="activities"
                    :multiple="false"
                    :searchable="true"
                    @select="validCompletActivieties()"
                    class="mb-2"
                    :internal-search="true"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :show-no-results="true"
                    :hide-selected="true"
                  >
                  </multiselect>
                  <div class="sub-card" style="background-color: var(--greenn2)">
                    <p style="color: var(--greenn)">
                      {{ activeCategory.description }}
                    </p>
                  </div>
                  <div class="sub-card" style="border-color: var(--greenn)">
                    <b-progress
                      :value="activeStepItem.length ? percentage : '0'"
                      :max="100"
                      :precision="1"
                      show-value
                      style="height: 30px"
                    ></b-progress>
                    <div class="metrics mt-3">
                      <div class="total">
                        <h1>{{ activeStepItem.length ? complete : "0" }}</h1>
                        <div>
                          <p>{{ complete == 1 ? "Atividade" : "Atividades" }}</p>
                          <p>{{ complete == 1 ? "completa" : "completas" }}</p>
                        </div>
                      </div>
                      <div class="total">
                        <h1>{{ activeStepItem.length ? pending : "0" }}</h1>
                        <div>
                          <p>{{ pending == 1 ? "Atividade" : "Atividades" }}</p>
                          <p>{{ pending == 1 ? "pendente" : "pendentes" }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="sub-card"
                    style="border-color: var(--greenn)"
                    v-if="
                      activeStepItem &&
                      activeStepItem.length &&
                      activeStepItem[currentStep] &&
                      (activeStepItem[currentStep].type != 'call' ||
                        currentStep == complete ||
                        currentStep == complete - 1)
                    "
                  >
                    <div class="activity-controls">
                      <div
                        v-if="activeStepItem[currentStep].type == 'email'"
                        v-b-modal.ModalEnviarEmailAtividadeLead
                      >
                        <img src="@/assets/icons/email.svg" style="height: 20px" alt="email" />
                        Enviar e-mail
                      </div>
                      <div
                        v-if="activeStepItem[currentStep].type == 'mensage'"
                        @click="scheduleMessage(activeStepItem[currentStep].body)"
                      >
                        <img style="height: 20px" src="@/assets/img/G-extension.png" alt="email" />
                        Agendar com Gmessage
                      </div>
                      <div
                        @click="whatsMessage(activeStepItem[currentStep].body)"
                        v-if="activeStepItem[currentStep].type == 'mensage'"
                      >
                        <img style="height: 20px" src="@/assets/icons/whatsfill.svg" alt="email" />
                        Enviar no WhatsApp
                      </div>
                      <div
                        @click="copyMessage(activeStepItem[currentStep].body)"
                        v-if="activeStepItem[currentStep].type == 'mensage'"
                      >
                        <img style="height: 20px" src="@/assets/icons/copy.svg" alt="email" />
                        Copiar mensagem
                      </div>
                      <div
                        @click="
                          currentStep == complete
                            ? checkDoneActivitie(activeStepItem[currentStep].id, false, index)
                            : checkDoneActivitie(activeStepItem[currentStep].id, true, index)
                        "
                        v-if="currentStep == complete || currentStep == complete - 1"
                      >
                        <img style="height: 20px" src="@/assets/icons/check2.svg" alt="email" />
                        {{ currentStep == complete ? "Marcar como feito" : "Desmarcar como feito" }}
                      </div>
                      <!-- <div v-if="activeStepItem[currentStep].type == 'call'">
                      <img src="@/assets/icons/phone.svg" alt="email" />
                    </div> -->
                    </div>
                  </div>
                </div>
                <component
                  :is="`${!isIframe ? 'router-link' : 'a'}`"
                  v-else
                  :to="{ name: 'ConfiguracoesSistema' }"
                  style="padding: 0 25px !important"
                >
                  <div
                    class="box-total cursor"
                    style="
                      grid-column-start: 1;
                      grid-column-end: 3;
                      height: 83px;
                      width: -webkit-fill-available;
                      margin: 20px;
                    "
                  >
                    <div class="total-vendas">
                      <p class="info">Nenhuma atividade configurada no sistema</p>
                    </div>
                  </div>
                </component>
              </div>
            </div>
          </b-tab>

          <b-tab v-if="leadsUnification" @click.once="openTab(4)" @click="paginacao = 4" key="4">
            <template #title>
              <img
                v-if="paginacao != 4"
                src="@/assets/img/icons/checkout.svg"
                alt="comerce"
                class="img-tab-title"
              />
              <img
                v-else
                src="@/assets/img/icons-active/checkout.svg"
                class="img-tab-title"
                alt="comerce"
              />
              <strong
                class="tab-title"
                :class="paginacao == 4 ? 'active' : 'non-active'"
                :style="paginacao == 4 ? 'max-width: 78px;' : ''"
                >Checkout</strong
              >
            </template>

            <DetailLeadCheckout :purchase-details="purchaseDetails" />
          </b-tab>

          <b-tab @click="paginacao = 5" key="5">
            <template #title>
              <img
                v-if="paginacao != 5"
                src="@/assets/img/icons/companie.svg"
                alt="companie"
                class="img-tab-title"
              />
              <img
                v-else
                src="@/assets/img/icons-active/companie.svg"
                class="img-tab-title"
                alt="companie"
              />
              <strong
                class="tab-title"
                :class="paginacao == 5 ? 'active' : 'non-active'"
                :style="paginacao == 5 ? 'max-width: 78px;' : ''"
                >Empresa</strong
              >
            </template>
            <!-- HIDDEN-FEATURE -->
            <div class="in-progress" v-if="true">
              <svg fill="#4ea934" width="200px" height="200px" viewBox="0 -8 72 72">
                <polygon
                  points="52.7 39.8 19.61 39.8 19.61 33.91 11.91 33.91 11.91 51.6 19.61 51.6 19.61 45.6 52.7 45.6 52.7 52 60.39 52 60.39 34.3 52.7 34.3 52.7 39.8"
                />
                <polygon points="33.3 12.01 23.21 12.01 11.13 31.21 21.22 31.21 33.3 12.01" />
                <polygon points="35.22 31.21 47.3 12.01 38.18 12.01 26.1 31.21 35.22 31.21" />
                <path d="M66.51,12,54.43,31.21H66.16c1.86,0,2.23-1,2.23-2.5V12Z" />
                <polygon points="49.55 31.21 61.63 12.01 52.18 12.01 40.1 31.21 49.55 31.21" />
                <path d="M5.64,12s-2-.3-2,2.17v17H6.25L18.33,12Z" />
              </svg>
              <h3 class="title">
                Atualizações em Progresso<br /><span
                  style="font-size: 14px; color: inherit; margin-top: 8px; opacity: 0.7"
                  >Estamos dedicados a melhorar sua experiência.<br />Em breve, traremos mais
                  novidades!</span
                >
              </h3>
            </div>
            <div class="grid2" style="grid-template-columns: 300px 1fr">
              <div>
                <h4 class="title">Empresa</h4>
                <div v-if="company && company.id" class="sub-card" style="position: relative">
                  <span class="company-name">{{ company.name }}</span>
                  <span
                    >Razão social:
                    {{ company.legal_name ? company.legal_name : "Sem razão social" }}</span
                  >
                  <span
                    >Líder:
                    {{
                      company.leads.find((lead) => lead.id == company.lead_id)
                        ? company.leads.find((lead) => lead.id == company.lead_id).name
                        : company.lead_id
                    }}</span
                  >
                  <span>CNPJ: {{ company.document ? company.document : "Sem CNPJ" }}</span>
                  <span
                    >Total de membros:
                    {{
                      company && company.leads && company.leads.length ? company.leads.length : ""
                    }}</span
                  >
                  <img
                    src="@/assets/icons/edit.svg"
                    class="cursor-pointer"
                    v-b-tooltip.hover
                    title="Editar empresa"
                    @click="showEditCompany = !showEditCompany"
                    style="position: absolute; bottom: 15px; right: 15px"
                  />
                </div>
                <div v-else class="box-total">
                  <div class="total-vendas">
                    <p class="info">Nenhuma empresa relacionada</p>
                  </div>
                </div>
                <b-collapse v-model="showEditCompany" v-if="lead">
                  <h4
                    class="title"
                    style="
                      display: flex;
                      justify-content: space-between;
                      margin: 0;
                      padding-bottom: 10px;
                    "
                  >
                    Editar empresa
                    <span style="display: inline; cursor: pointer" @click="showEditCompany = false"
                      >✕</span
                    >
                  </h4>
                  <div class="sub-card">
                    <label style="margin: 0">Razão social</label>
                    <input
                      class="w-100"
                      type="text"
                      name="email"
                      id="email"
                      v-model="editCompany.legal"
                    />
                    <label style="margin: 0">CNPJ</label>
                    <input
                      class="w-100"
                      type="text"
                      name="email"
                      id="email"
                      v-model="editCompany.document"
                      v-mask="['##.###.###/####-##']"
                    />
                    <button class="btn-follow w-100" @click="updateCompany()">
                      Salvar alterações
                    </button>
                  </div>
                  <h4
                    class="title"
                    v-if="company.leads && company.leads.length > 1"
                    style="margin: 0; padding: 10px 0"
                  >
                    Editar líder da empresa
                  </h4>
                  <div class="sub-card" v-if="company.leads && company.leads.length > 1">
                    <multiselect
                      v-model="editCompany.leader"
                      id="ajax"
                      label="name"
                      track-by="id"
                      placeholder="Selecione o novo líder"
                      selectLabel=""
                      :allow-empty="false"
                      deselectLabel="✔️"
                      selectedLabel="✔️"
                      open-direction="bottom"
                      :options="company.leads.filter((x) => x.id != company.lead_id)"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="true"
                      :clear-on-select="false"
                      :close-on-select="true"
                      :show-no-results="true"
                      :hide-selected="true"
                    >
                    </multiselect>
                    <b-form-checkbox
                      style="z-index: unset !important"
                      v-model="editCompany.toChange"
                      name="is_main"
                      size="lg"
                      switch
                    >
                      <p class="info-checkbox" style="font-size: 12px">
                        Mover no CRM para a posição do líder anterior
                      </p>
                    </b-form-checkbox>
                    <button class="btn-follow w-100 mt-2" @click="updateLeaderCompany()">
                      Atualizar líder
                    </button>
                  </div>
                </b-collapse>
              </div>
              <div>
                <h4 class="title">Membros</h4>
                <div
                  v-if="company && company.leads && company.leads.length"
                  style="
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
                    gap: 14px;
                  "
                >
                  <div
                    class="sub-card card-members"
                    v-for="(member, index) in company.leads"
                    :key="index"
                    @click="fetchLead(member.id)"
                  >
                    <div
                      class="seller-no-ft"
                      :class="member.id == company.lead_id ? 'leader-after' : ''"
                    >
                      <h3 style="text-align: center">
                        {{ getMetaInitialLead(member.name) }}
                      </h3>
                    </div>
                    <div class="lead-texts">
                      <p class="lead-name">{{ member.name }} - {{ member.leadscore }}°</p>
                      <p>
                        {{ member.email ? member.email : "Sem e-mail cadastrado" }}
                      </p>
                      <p>
                        {{ member.phone ? member.phone : "Sem telefone cadastrado" }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>

        <div
          v-if="loading"
          style="
            margin-top: 10px !important;
            width: -webkit-fill-available;
            display: flex;
            height: 100%;
            align-items: center;
          "
        >
          <div class="lds-ellipsis">
            <div style="background: var(--greenn)"></div>
            <div style="background: var(--greenn)"></div>
            <div style="background: var(--greenn)"></div>
            <div style="background: var(--greenn)"></div>
          </div>
        </div>
      </div>

      <template v-slot:footer>
        <div></div>
      </template>
      <ModalEmail :selected_lead="lead" :selected_activity="activeStepItem[currentStep]" />
      <ModalBusiness
        @update="fetchLead(lead.id)"
        :lead="lead"
        :is_pipeline="true"
        :lead_id="lead.id"
        :reasonType="reasonType"
        :pipeline_id="selectedPipelineId"
      />
      <ModalSituacao
        @lossModal="handleSituationModal('loss')"
        @gainModal="handleSituationModal('gain')"
      />
      <ModalLeadPhoto v-if="showModalPhoto" ref="Lead-PhotoEdit" @setPhoto="setPhoto" @deletePhoto="deletePhoto" :lead="lead" />
      <ModalLeadFile v-if="showModal" ref="Lead-FileEdit" :lead="lead" @changeFile="changeFile" />
      <div
        v-if="preview"
        class="openModalImg"
        :class="isVertical ? 'openModalImgVertical' : 'openModalImgHorizontal'"
        @click.self="preview = !preview"
      >
        <div>
          <div @click="closeImg" class="closeModalImg">✕</div>
          <img :src="currentImg" alt="img" />
        </div>
      </div>
    </GrModal>
  </div>
</template>
<script>
import ProductCard from "./ProductCard.vue";
import leadScript from "@/mixins/lead.js";
import WhatsAppMessages from "@/components/Apps/LogMessages/WhatsAppMessages.vue";
import ModalEmail from "../LeadUnico/ModalActivityEmail.vue";
import ViewAddress from "../LeadUnico/ViewAddress.vue";
import UpdateLead from "../LeadUnico/UpdateLead.vue";
import historyCard from "../LeadUnico/historyCard.vue";
import ModalLeadPhoto from "../LeadUnico/ModalLeadPhoto.vue";
import ModalLeadFile from "../LeadUnico/ModalLeadFile.vue";
import Vue from "vue";
import { Money } from "v-money";
import Multiselect from "vue-multiselect";
import VuePhoneNumberInput from "vue-phone-number-input";
import ModalBusiness from "@/components/LeadUnico/ModalBusiness.vue";
import ModalSituacao from "../LeadUnico/ModalSituacao.vue";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment-timezone";
import PipelineService from "@/services/resources/PipelineService";
import LeadService from "@/services/resources/LeadService";
import TagService from "@/services/resources/TagService";
import AdicionalService from "@/services/resources/AdicionalService";
import EmailService from "@/services/resources/EmailService";
import NotaService from "@/services/resources/NotaService";
import SellerAutomation from "@/services/resources/SellerAutomation";
import ConfigProdutosService from "@/services/resources/ConfigProdutosService";
import WhatsappService from "@/services/resources/WhatsappService";
import CompanyService from "@/services/resources/CompanyService";
import AtividadesService from "@/services/resources/AtividadesService";
import FunnelService from "@/services/resources/FunnelService";
import SettingsService from "@/services/resources/SettingsService";
import GoogleService from "@/services/resources/GoogleService";
import ActivitieService from "@/services/resources/ActivitieService";
import FormService from "@/services/resources/FormService";
import LeadMessageService from "@/services/resources/LeadMessageService";
import GreennService from "@/services/resources/GreennService";
import PageService from "@/services/resources/PageService";
import { DetailLeadCheckout } from "../LeadUnico/Checkout";

const servicePage = PageService.build();
const servicePipeline = PipelineService.build();
const serviceLead = LeadService.build();
const serviceTag = TagService.build();
const serviceAdicional = AdicionalService.build();
const serviceEmail = EmailService.build();
const serviceNota = NotaService.build();
const serviceSellerAutomation = SellerAutomation.build();
const serviceProduct = ConfigProdutosService.build();
const serviceWhatsapp = WhatsappService.build();
const serviceCompanies = CompanyService.build();
const serviceAtivadade = AtividadesService.build();
const serviceFunnel = FunnelService.build();
const serviceSettings = SettingsService.build();
const serviceActivitie = ActivitieService.build();
const serviceGoogle = GoogleService.build();
const serviceForm = FormService.build();
const serviceLeadMessage = LeadMessageService.build();
const serviceGreenn = GreennService.build();

export default {
  mixins: [leadScript],
  components: {
    Multiselect,
    ModalLeadPhoto,
    ModalLeadFile,
    VuePhoneNumberInput,
    Money,
    DateRangePicker,
    ModalBusiness,
    ModalEmail,
    ViewAddress,
    UpdateLead,
    WhatsAppMessages,
    historyCard,
    ModalSituacao,
    ProductCard,
    DetailLeadCheckout,
  },
  props: [
    "id_lead_selected",
    "entireLead",
    "tags_props",
    "sellers",
    "forms",
    "inList",
    "pipeline_id",
    "userLevelData",
    "recursosData",
    "isIframe",
    "userData",
    "productsData",
    "newPicture",
    "leadsUnification"
  ],
  data() {
    return {
      showModal: false,
      showModalPhoto: false,
      widthMax: null,
      vendedorHist: {},
      sellerSelect: false,
      sellerSearchHist: "",
      loadingHist: false,
      historics: {
        page: [],
        funnel: [],
        broadcast: [],
        forms: [],
      },
      showAllTags: false,
      currentModal: "",
      loadingCRM: false,
      config: {
        hasCRM: true,
        isSeller: false,
        hasPreSeller: true,
        hasMessageHistoric: true,
        changesTag: true,
        canAssign: true,
      },
      showInfoMessage: false,
      currentDate: "",
      listLogMessage: [],
      listMessageInfo: [],
      showContainerAddProduct: true,
      showAddProduct: false,
      showAddAdicional: false,
      company: "",
      edit: {
        name: "",
        email: "",
        phone: "",
        score: "",
      },
      formAddress: {
        city: "",
        countryCode: "",
        id: "",
        neighborhood: "",
        number: 0,
        state: "",
        street: "",
        zipCode: "",
      },
      editProduct: {
        price: "",
        finalPrice: "",
        discount: "",
        quantity: 1,
        quantityBeforeMutate: 1,
      },
      editAdicional: {
        name: "",
        value: "",
      },
      editCompany: {
        legal: "",
        leader: "",
        toChange: true,
        document: "",
      },
      dateRange1: {
        startDate: "",
        endDate: "",
      },
      dateRange2: {
        startDate: "",
        endDate: "",
      },
      renderDate: true,
      meetingDuration: 60,
      localeData: {
        direction: "ltr",
        format: "dd/mm/yyyy - HH:MM",
        // format: "isoDateTime",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      showEditCompany: false,
      loadingMensagem: false,
      sellerWord: "",
      filterSearchTags: "",
      tagSearch: "",
      sellerSearch: "",
      filterSearchSellers: "",
      notaSelected: {},
      mensage: "",
      tel: "",
      translations: {
        countrySelectorLabel: "",
        countrySelectorError: "",
        phoneNumberLabel: "",
        example: "",
      },
      pipeline: {},
      pipelines: [],
      tags: [],
      tag: {},
      board: {},
      boards: [],
      lead: {},
      email: "",
      score: 0,
      nome: "",
      valor: "",
      count: 1,
      wpp: [],
      mensagem: "Olá, {{nome}}! Tudo bem?",
      timeline: [],
      vendedor: null,
      pre_vendedor: null,
      nota: "",
      products: [],
      lead_products: [],
      selectProduct: "",
      valorPorcentagem: 0,
      valorLiquido: 0,
      quantity: 1,
      paginacao: 0,
      loading: true,
      google_calendar: false,
      event_title: "",
      event_start_date: "",
      event_end_date: "",
      hasAccount: false,
      selected: "",
      confirmacao: false,
      realNumber: "",
      level: "admin",
      user: {},
      recursos: {},
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: " ",
        precision: 2,
        masked: false,
      },
      pipelines_lead: [],
      stops: [],
      activities: [],
      activeCategory: {},
      activeStepItem: [],
      textBody_show: false,
      textBody: false,
      percentage: 0,
      pending: 0,
      complete: 0,
      currentStep: 0,
      total: 0,
      companies: [],
      preview: false,
      isVertical: true,
      disappear: false,
      reasonType: "loss",
      selectedPipelineId: 0,
      greennLeadOffers: [],
      purchaseDetails: [],
      zipCode: "",
      countryCode: "",
      state: "",
      city: "",
      neighborhood: "",
      street: "",
      number: "",
    };
  },
  filters: {
    moment: function (data) {
      return moment(data).format("hh:mm");
    },
  },
  computed: {
    leadPicture() {
      if (this.newPicture) {
        return this.newPicture;
      }
      if (
        this.lead.metas?.find((meta) => meta.meta_key == "photo-lead") &&
        this.lead.metas?.find((meta) => meta.meta_key == "photo-lead").meta_value
      ) {
        return this.lead.metas.find((meta) => meta.meta_key == "photo-lead").meta_value;
      }
      return false;
    },
    filteredProducts() {
      return this.products.filter(
        (x) => x.status == "active" && this.lead_products.every((y) => y.product_id != x.id)
      );
    },
    dataAtual() {
      return moment().format("YYYY-MM-DD");
    },
    currentTags() {
      if (this.tags.length <= 10 || this.showAllTags) {
        return this.tags;
      } else {
        return this.tags.slice(0, 7);
      }
    },
    filterSellerHist() {
      return !this.sellerSearchHist.length
        ? this.sellers
        : this.sellers.filter(
            (item) =>
              item.id.toString().includes(this.sellerSearchHist.trim()) ||
              item.email.toLowerCase().includes(this.sellerSearchHist.toLowerCase().trim()) ||
              item.first_name.toLowerCase().includes(this.sellerSearchHist.toLowerCase().trim()) ||
              item.last_name.toLowerCase().includes(this.sellerSearchHist.toLowerCase().trim())
          );
    },
    appDev() {
      return process.env.VUE_APP_TITLE === "GDigitalDev";
    },
  },
  watch: {
    tagSearch: "filterSearchTagsUpdate",
    sellerSearch: "filterSearchSellersUpdate",
    valorLiquido: "ajustValues",
    "editProduct.finalPrice": "ajustValuesProduct",
  },
  methods: {
    formattedDataPurchase(data) {
      const { lead_sessions, methods } = data;

      const formattedEadSessions = lead_sessions
        ? lead_sessions.map((session) => ({
            type: Object.keys(session)[0],
            value: session[Object.keys(session)[0]],
            count: session.count,
          }))
        : [];

      const formattedMethods = methods
        ? methods.map((method) => ({
            type: method.method.toLowerCase(),
            value: method.method.toLowerCase(),
            count: method.count,
          }))
        : [];

      this.purchaseDetails = [...formattedEadSessions, ...formattedMethods];
    },

    debounceZipCode: _.debounce(function (zipCode) {
      const code = zipCode.target.value.replace("-", "");
      if (code.length < 8) {
        return;
      }
      this.checkZipCode(code);
    }, 100),
    async checkZipCode(zipCode) {
      this.$refs.neighborhood.focus();
      const address = await this.getAddress(zipCode);
      if (address.erro) {
        this.$grToast.toast("Endereço não encotrado. Por favor, confira o CEP", {
          title: "Lead",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      this.state = address.uf;
      this.city = address.localidade;
      this.neighborhood = address.bairro;
      this.street = address.logradouro;
      this.countryCode = address.uf;
      this.updateAddress();
    },
    async getAddress(zipCode) {
      return new Promise((resolve) => {
        fetch(`https://viacep.com.br/ws/${zipCode}/json`)
          .then((request) => request.json())
          .then((data) => {
            resolve(data);
          });
      });
    },
    updateAddress() {
      if (this.formAddress.zipCode === "") return;

      serviceLead
        .createId(this.formAddress)
        .then((resp) => {
          if (resp.status !== "SUCCESS") return;

          this.lead.address = resp?.messages;
        })
        .catch(() => {
          this.$grToast.toast("Erro ao salvar endereço do lead", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    getMaxWidthSubCard() {
      this.$nextTick(() => {
        this.widthMax = document.querySelector(".grid2 .sub-card").offsetWidth - 160;
      });
    },
    updateDataIframeLead(dataKey, newValue) {
      const mapping = {
        userLevelData: "level",
        recursosData: "recursos",
        userData: "user",
        tagsData: "tags_props",
      };
      if (mapping[dataKey]) {
        this[mapping[dataKey]] = newValue;
      }
    },
    updateLeadPipeline() {
      this.lead.tags = this.currentTags;
      this.$emit("update", this.lead);
      this.$emit("closeModal", { type: "closeModal" });
    },
    openTab(n) {
      switch (n) {
        case 1:
          this.fetchTimeLine();
          setTimeout(() => {
            this.getNextPage();
          }, 500);
          this.getListMessage();
          return;
        case 2:
          this.fetchWpp(this.lead.id);
          return;
        case 3:
          this.getCategories();
          return;
        default:
          break;
      }
    },
    async openModalFile() {
      this.showModal = true;
      this.showModalPhoto = false;
      await this.$nextTick();
      this.$grModal.show("Lead-FileEdit", this);
    },
    openImg(item) {
      const img = new Image();
      img.onload = () => {
        if (img.width > img.height) {
          this.isVertical = false;
        } else {
          this.isVertical = true;
        }
      };
      img.src = item;
      this.currentImg = item;
      this.preview = true;
    },
    closeImg() {
      this.preview = false;
    },
    changeFile(event) {
      const newNote = {
        content: event,
        lead_id: this.lead.id,
      };
      serviceNota.create(newNote).then((resp) => {
        this.lead.notes.push(resp.note);
      });
    },
    setPhoto(urlPhoto) {
      if (this.lead.metas.find((meta) => meta.meta_key == "photo-lead")) {
        this.photoSelect = true;
        this.updatePhoto(urlPhoto);
      } else {
        this.creatPhoto(urlPhoto);
      }
    },
    deletePhoto() {
      let meta = this.lead.metas.find((meta) => meta.meta_key == "photo-lead");
      let metaId = meta.id;
      let data = {
        id: `${this.lead.id}/${metaId}`,
      };
      serviceAdicional
        .destroy(data)
        .then(() => {
          let adicionalIndex = this.lead.metas.findIndex((adicional) => adicional.id == metaId);
          this.lead.metas.splice(adicionalIndex, 1);
        })
        .catch(() => {
          this.$grToast.toast("Erro ao deletar imagem do lead", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    creatPhoto(urlPhoto) {
      let data = {
        lead_id: this.lead.id,
        meta_key: "photo-lead",
        meta_value: urlPhoto,
      };
      serviceAdicional
        .create(data)
        .then((resp) => {
          let newItem = {
            id: resp.id,
            meta_key: resp.meta_key,
            meta_value: resp.meta_value,
          };
          this.lead.metas.push(newItem);
        })
        .catch((err) => {
          this.$grToast.toast("Erro ao editar a foto do Lead", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    updatePhoto(urlPhoto) {
      let meta = this.lead.metas.find((meta) => meta.meta_key == "photo-lead");
      let metaId = meta.id;

      let data = {
        id: metaId,
        lead_id: this.lead.id,
        meta_key: "photo-lead",
        meta_value: urlPhoto,
      };
      serviceAdicional
        .update(data)
        .then(() => {
          this.lead.metas.find((adicional) => adicional.id == metaId).meta_key = "photo-lead";
          this.lead.metas.find((adicional) => adicional.id == metaId).meta_value = urlPhoto;
        })
        .catch((err) => {
          this.$grToast.toast("Erro ao editar a foto do Lead", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    changeSelectedProduct() {
      if (this.valorPorcentagem == 0) {
        var value = (this.valorLiquido = this.selectProduct.product_value);
        return;
      }
      this.valorLiquido = (this.selectProduct.product_value * (100 - this.valorPorcentagem)) / 100;
    },
    ajustValues(isFromDiscount) {
      if (this.quantity < 1) {
        this.$grToast.toast("No mínimo uma cópia do produto", {
          title: "Produtos",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.quantity = 1;
        return;
      }

      if (this.quantity > 100000) {
        this.$grToast.toast("No máximo cem mil cópias do produto", {
          title: "Produtos",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.quantity = 1;
        return;
      }

      if (isFromDiscount === true) {
        if (this.valorPorcentagem == 0) {
          this.valorLiquido = this.selectProduct.product_value * this.quantity;
          return;
        }
        this.valorLiquido =
          (this.selectProduct.product_value * this.quantity * (100 - this.valorPorcentagem)) / 100;
      } else {
        let newValorPorcentagem =
          100 - (this.valorLiquido * 100) / (this.selectProduct.product_value * this.quantity);
        if (newValorPorcentagem > 100) {
          this.valorPorcentagem = 100;
          this.valorLiquido = 0;
        } else if (newValorPorcentagem < 0) {
          this.valorPorcentagem = 0;
          this.valorLiquido = this.selectProduct.product_value * this.quantity;
        } else {
          this.valorPorcentagem = Math.round(newValorPorcentagem * 100) / 100;
        }
      }
    },
    ajustValuesProduct(isFromDiscount) {
      let quantity = +this.editProduct.quantity;
      if (quantity < 1) {
        this.$grToast.toast("No mínimo uma cópia do produto", {
          title: "Produtos",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.editProduct.quantity = this.editProduct.quantityBeforeMutate;
        return;
      }

      if (quantity > 100000) {
        this.$grToast.toast("No máximo cem mil cópias do produto", {
          title: "Produtos",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.editProduct.quantity = this.editProduct.quantityBeforeMutate;
        return;
      }

      if (isFromDiscount === true) {
        if (this.editProduct.discount == 0) {
          this.editProduct.finalPrice = this.editProduct.price * quantity;
          return;
        }
        this.editProduct.finalPrice =
          (this.editProduct.price * quantity * (100 - this.editProduct.discount)) / 100;
      } else {
        let newValorPorcentagem =
          100 - (this.editProduct.finalPrice * 100) / (this.editProduct.price * quantity);
        if (newValorPorcentagem > 100) {
          this.editProduct.discount = 100;
          this.editProduct.finalPrice = 0;
        } else if (newValorPorcentagem < 0) {
          this.editProduct.discount = 0;
          this.editProduct.finalPrice = this.editProduct.price * quantity;
        } else {
          this.editProduct.discount = Math.round(newValorPorcentagem * 100) / 100;
        }
      }
    },
    async validCompletActivieties() {
      let dataActive = {
        id: `activities/`,
        status: "active",
        lead_id: this.lead.id,
        category_id: this.activeCategory.id,
      };

      await serviceAtivadade.createId(dataActive).then((res) => {
        this.activeStepItem = res;
      });

      var array_count = 0;
      for (let x = 0; x < this.activeStepItem.length; x++) {
        const element2 = this.activeStepItem[x];
        if (
          element2.reasons &&
          element2.reasons.done &&
          (element2.reasons.done === 1 || element2.reasons.done === true)
        ) {
          array_count++;
        }
      }
      this.total = this.activeStepItem.length;
      this.pending = this.activeStepItem.length - array_count;
      this.complete = array_count;
      this.currentStep = array_count;
      this.percentage = (array_count / this.activeStepItem.length) * 100;
    },
    gainAction(type, pipeline_id) {
      Vue.swal({
        title: "Lead",
        text: `Deseja ${type == "ganho" ? "definir o lead como ganho" : "reabrir o lead"}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            id: "lead/change-status",
            lead_id: this.lead.id,
            pipeline_id: pipeline_id,
          };
          if (type == "ganho") {
            data.reason = "Lead ganho";
            data.status = "done";
          } else {
            data.reason = "Lead reaberto";
            data.status = "active";
          }

          serviceActivitie.createId(data).then(() => {
            this.$grToast.toast(type == "ganho" ? "Oportunidade ganha!" : "Lead reaberto!", {
              title: "Lead",
              variant: "info",
              autoHideDelay: 300,
              appendToast: true,
            });
            this.fetchLead(this.lead.id);
          });
        }
      });
    },
    filterName(message) {
      if (message) {
        var words = this.lead.name.split(" ");
        var name = words[0].charAt(0).toUpperCase() + words[0].slice(1);
        return message.replace("{{nome}}", name);
      }
    },
    copyMessage(message) {
      var words = this.lead.name.split(" ");
      var name = words[0].charAt(0).toUpperCase() + words[0].slice(1);
      navigator.clipboard.writeText(message.replace("{{nome}}", name)).then(() => {
        this.$grToast.toast("Mensagem copiada", {
          title: "Lead",
          variant: "info",
          autoHideDelay: 300,
          appendToast: true,
        });
      });
    },
    whatsMessage(message) {
      if (!this.lead.phone) {
        this.$grToast.toast("O lead precisa de um número de telefone", {
          title: "Lead",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
      var words = this.lead.name.split(" ");
      var name = words[0].charAt(0).toUpperCase() + words[0].slice(1);
      var newMessage = encodeURIComponent(message.replace("{{nome}}", name));
      var url = `https://wa.me//${this.lead.phone.replace("+", "")}?text=${newMessage}`;
      window.open(url, "_blank");
    },
    scheduleMessage(message) {
      if (!this.lead.phone) {
        this.$grToast.toast("O lead precisa de um número de telefone", {
          title: "Lead",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      var data = {
        id: `send/${this.lead.id}`,
        message: message,
        date_added: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      serviceWhatsapp
        .createId(data)
        .then((resp) => {
          this.loading = false;
          if (resp[0] == "Mensagem agendada com sucesso!") {
            this.wpp.unshift(resp[1]);
            this.$grToast.toast(`Mensagem agendada, acompanhe na aba "Mensagens"`, {
              title: "Lead",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        })
        .catch(() => {
          this.$grToast.toast("Erro ao agendar mensagem", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    currentSituation(item) {
      let lead = this.lead.situations.find((situation) => situation.pipeline_id === item.id);
      if (lead && lead.situation == "done") {
        return "gain";
      }

      if (lead && lead.situation == "inactive") {
        return "loss";
      }

      if (lead && lead.situation == "active") {
        return "open";
      }

      return false;
    },
    currentSituationTitle(item) {
      let situation = this.lead.situations.find((situation) => situation.pipeline_id === item.id);
      return situation && situation.reason && situation.reason.length > 35
        ? situation.reason.substr(0, 35) + "..."
        : situation.reason;
    },
    decideSituation(pipelineId) {
      this.selectedPipelineId = pipelineId;
      this.$bvModal.show("modal-situation");
    },
    handleSituationModal(situation) {
      this.reasonType = situation;
      this.$bvModal.show("modal-business");
    },
    reopenSituation(pipelineId) {
      Vue.swal({
        title: "Lead",
        text: `Deseja reabrir o lead?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            id: "lead/change-status",
            lead_id: this.lead.id,
            pipeline_id: pipelineId,
          };

          data.reason = "Lead reaberto";
          data.status = "active";

          serviceActivitie.createId(data).then(() => {
            this.$grToast.toast("Lead reaberto!", {
              title: "Lead",
              variant: "info",
              autoHideDelay: 300,
              appendToast: true,
            });
            this.fetchLead(this.lead.id);
          });
        }
      });
    },
    checkDoneActivitie(id, status, index) {
      var data = {
        id: "lead",
        lead_id: this.lead.id,
        activitie_id: id,
        done: status ? false : true,
        reason: "null",
      };
      serviceActivitie
        .createId(data)
        .then((resp) => {
          if (!status) {
            // this.currentStep = index + 1;
            this.complete++;
            this.pending--;
            this.percentage = (this.complete / this.total) * 100;
          } else {
            // this.currentStep = index - 1;
            this.complete--;
            this.pending++;
            this.percentage = (this.complete / this.total) * 100;
          }
        })
        .finally(() => {});
    },
    enviarWhatsapp() {
      const url = `https://api.whatsapp.com/send?phone=${this.lead.phone}&text=${encodeURIComponent(
        this.textBody
      )}`;
      window.open(url, "_blank");
    },
    async getCategories() {
      let data = {
        status: "active",
        lead_id: this.lead.id,
      };
      let dataActive = {
        id: `activities/`,
        status: "active",
        lead_id: this.lead.id,
      };
      await serviceAtivadade.search(data).then((resp) => {
        this.activeCategory = resp[0];
        this.activities = resp;
        dataActive.category_id = this.activeCategory?.id;
      });
      if (dataActive.category_id) {
        await serviceAtivadade.createId(dataActive).then((res) => {
          this.activeStepItem = res;
          this.validCompletActivieties();
        });
      }
    },
    updateLeaderCompany() {
      if (!this.editCompany.leader) {
        this.$grToast.toast("Selecione um membro", {
          title: "Empresa",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      var data = {
        id: "/changeLeader",
        lead_id: this.editCompany.leader.id,
        company_id: this.company.id,
        option: this.editCompany.toChange ? "no" : "yes",
      };
      serviceCompanies
        .createId(data)
        .then((resp) => {
          this.company.lead_id = this.editCompany.leader.id;
        })
        .catch((error) => {
          this.$grToast.toast("Erro ao alterar líder", {
            title: "Empresa",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    updateCompany() {
      var data = {
        id: this.company.id,
        name: this.company.name,
        lead_id: this.company.lead_id,
        legal_name: this.editCompany.legal,
        document: this.editCompany.document,
      };
      serviceCompanies
        .update(data)
        .then((resp) => {
          this.company.legal_name = this.editCompany.legal;
          this.company.document = this.editCompany.document;
          this.showEditCompany = false;
        })
        .catch((error) => {
          this.$grToast.toast("Erro ao atualizar empresa!", {
            title: "Empresa",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: false,
          });
        });
    },
    selectSellers(word) {
      if (this.config.isSeller && !this.config.canAssign) {
        this.$grToast.toast(
          "Sua posição não é permitida de alterar os vendedores associados ao lead",
          {
            title: "Ação negada",
            variant: "danger",
            autoHideDelay: 500,
            appendToast: true,
          }
        );
        return;
      }
      if (
        (word == "vendedor" && this.currentModal == "seller") ||
        (word != "vendedor" && this.currentModal == "pre-seller")
      ) {
        this.currentModal = "";
        return;
      }
      this.sellerWord = word;
      this.currentModal = word == "vendedor" ? "seller" : "pre-seller";
    },
    filterSearchTagsUpdate() {
      this.filterSearchTags = this.tags_props?.filter((item) => {
        const nameMatches = item.name?.toLowerCase().includes(this.tagSearch.toLowerCase());
        const idMatches = this.tags.some((tag) => tag.id === item.id);
        return nameMatches && !idMatches;
      });
    },
    filterSearchSellersUpdate() {
      this.filterSearchSellers = !this.sellerSearch.length
        ? this.sellers
        : this.sellers.filter(
            (item) =>
              item.id.toString().includes(this.sellerSearch.trim()) ||
              item.email.toLowerCase().includes(this.sellerSearch.toLowerCase().trim()) ||
              item.first_name.toLowerCase().includes(this.sellerSearch.toLowerCase().trim()) ||
              item.last_name.toLowerCase().includes(this.sellerSearch.toLowerCase().trim())
          );
    },
    gainAction(type) {
      Vue.swal({
        title: "Lead",
        text: `Deseja ${type == "ganho" ? "definir o lead como ganho" : "reabrir o lead"}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            id: "lead/change-status",
            lead_id: this.lead.id,
          };
          if (type == "ganho") {
            data.reason = "Lead ganho";
            data.status = "done";
          } else {
            data.reason = "Lead reaberto";
            data.status = "active";
          }

          serviceActivitie.createId(data).then(() => {
            this.$grToast.toast(type == "ganho" ? "Oportunidade ganha!" : "Lead reaberto!", {
              title: "Lead",
              variant: "info",
              autoHideDelay: 300,
              appendToast: true,
            });
            this.fetchLead(this.lead.id);
          });
        }
      });
    },
    fetchConfigSeller() {
      serviceSettings.read({ id: "lead" }).then((resp) => {
        if (resp.find((meta) => meta.meta_key == "save_messages_zap")) {
          this.config.hasMessageHistoric =
            resp.find((meta) => meta.meta_key == "save_messages_zap")?.meta_value == "true"
              ? true
              : false;
        } else {
          this.config.hasMessageHistoric = true;
        }
      });
      serviceSettings.read({ id: "seller" }).then((resp) => {
        if (resp.find((meta) => meta.meta_key == "has_pre_seller")) {
          this.config.hasPreSeller =
            resp.find((meta) => meta.meta_key == "has_pre_seller")?.meta_value == "true"
              ? true
              : false;
          this.config.canAssign =
            resp.find((meta) => meta.meta_key == "seller_can_transfer_lead")?.meta_value == "true"
              ? true
              : false;
        } else {
          this.config.hasPreSeller = true;
          this.config.canAssign = true;
        }
      });
      serviceSettings.read({ id: "pipeline" }).then((resp) => {
        if (resp.find((meta) => meta.meta_key == "add_tag_pipeline")) {
          this.config.changesTag =
            resp.find((meta) => meta.meta_key == "add_tag_pipeline")?.meta_value == "true"
              ? true
              : false;
        } else {
          this.config.changesTag = true;
        }
      });
      this.config.hasCRM = this.$store.getters.recursos.ncrm > 0 ? true : false;
      this.config.isSeller = this.level == "seller" ? true : false;
    },
    hasGoogleConnection() {
      serviceGoogle.read({ id: `client/` }).then((res) => {
        if (res && res.user_id) {
          this.hasAccount = true;
          this.getLeadCalendar();
        }
      });
    },
    getLeadCalendar() {
      serviceGoogle.read({ id: `events/leadEvent/${this.lead.id}` }).then((res) => {
        if (!res.summary) {
          return;
        }
        this.google_calendar = true;
        this.event_title = res.summary;
        this.event_end_date = moment(res.end.dateTime).format("YYYY-MM-DD[T]HH:mm:ss");
        this.event_start_date = moment(res.start.dateTime).format("YYYY-MM-DD[T]HH:mm:ss");
      });
    },
    saveGoogleEvent() {
      let end_time = moment(
        (moment(this.dateRange1.startDate).unix() + this.meetingDuration * 60) * 1000
      ).format("YYYY-MM-DD[T]HH:mm:ss");
      let start_time = moment(this.dateRange1.startDate).format("YYYY-MM-DD[T]HH:mm:ss");
      var event = {
        id: `events/criarOuEditar/${this.lead.id}`,
        email: this.user.email,
        end_datetime: end_time,
        start_datetime: start_time,
        lead_id: this.lead.id,
        summary: this.event_title,
      };
      serviceGoogle
        .createId(event)
        .then(() => {
          this.$grToast.toast("Agendamento com Google Calendar realizado.", {
            title: "Lead",
            variant: "info",
            autoHideDelay: 500,
            appendToast: true,
          });
        })
        .catch((err) => {
          this.$grToast.toast("Erro ao realizar agendamento com Google Calendar.", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 500,
            appendToast: true,
          });
        });
    },
    editNota() {
      if (!this.nota) {
        this.$grToast.toast("Escreva algo para editar a nota", {
          title: "Lead",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      var data = {
        id: `/${this.notaSelected.lead_id}/${this.notaSelected.id}`,
        content: this.nota,
        lead_id: this.notaSelected.lead_id,
      };
      serviceNota
        .update(data)
        .then((resp) => {
          this.notaSelected = {};
          this.nota = "";
          this.fetchLead(this.lead.id);
        })
        .catch(() => {
          this.$grToast.toast("Erro ao atualizar nota", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    deletarNota(id) {
      Vue.swal({
        title: "Lead",
        text: `Deseja deletar a nota?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const deleteIndex = this.lead.notes.findIndex((x) => x.id == id);
          this.lead.notes.splice(deleteIndex, 1);
          serviceNota
            .delete(`${this.lead.id}/${id}`)
            .then(() => {})
            .catch(() => {
              this.$grToast.toast("Erro ao deletar nota", {
                title: "Lead",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
    getNumber(e) {
      if (e.countryCode == "BR") {
        this.realNumber = e.formattedNumber.slice(0, 14);
        return;
      }
      this.realNumber = e.formattedNumber;
    },
    getUserInfo(user) {
      if (!user) {
        return "Desconhecido";
      }
      var result = this.sellers.find((x) => x.id == user);
      if (result) {
        return result;
      }
      return "Desconhecido";
    },
    async openModal() {
      this.loading = true;
      this.loadingHist = true;
      this.lead_products = "";
      this.valor = "";
      this.loadingCRM = true;
      this.showEditCompany = false;
      this.currentDate = new Date();
      this.showContainerAddProduct = true;
      this.showAddProduct = false;
      this.showAddAdicional = false;
      this.event_end_date = "";
      this.event_start_date = "";
      this.nota = "";
      this.notaSelected = {};
      this.google_calendar = false;
      this.pipeline = "";
      this.timeline = [];
      this.pipelines_lead = [];
      this.paginacao = 0;
      await this.fetchPipelineLead(this.id_lead_selected.id);
      await this.fetchLead(this.id_lead_selected.id);
      if(this.leadsUnification){
        await this.getGreennLeadInformations(this.id_lead_selected.id);
      }
      await this.fetchConfigSeller();
    },
    async ajustTab() {
      await this.$nextTick();
      if(!this.leadsUnification){
        const element5 = document.querySelector('.TabHeader .nav-item a[aria-posinset="5"]');
        element5.style.borderRadius = '0 10px 0 0';
        element5.style.borderRight = '1px solid var(--white-medium)';
      }
    },
    openModalTag() {
      this.$bvModal.show("add-tag");
    },
    async openModalPhoto() {
      this.showModal = false;
      this.showModalPhoto = true;
      await this.$nextTick();
      this.$grModal.show("Lead-PhotoEdit", this);
    },
    selectedPipe(e) {
      if (this.inList && this.pipeline.tags[0].id === undefined) {
        if (e.id != null) {
          var tags_selecteds = e.tags;
          var tags = [];
          for (let i = 0; i < tags_selecteds.length; i++) {
            const element = tags_selecteds[i];
            serviceTag.read(`/show/${element}`).then((resp) => {
              tags.push(resp);
            });
          }
          if (this.pipeline.tags[0].id === undefined) {
            const pipelineSelec = this.pipelines.find((item) => item.id === e.id);
            pipelineSelec.tags = tags;
          }
        }
      }
    },
    fetchPipelines(page = 1) {
      var data = {
        page: page,
        per_page: 1000,
      };
      var pipelines = [];
      servicePipeline
        .search(data)
        .then((response) => {
          let addData = response.data;
          if (this.inList !== true) {
            for (let index = 0; index < addData.length; index++) {
              const element = addData[index];
              element.tags = element.tags.map((tag_id) =>
                this.tags_props.find((tag) => tag.id == tag_id)
              );
              addData[index] = element;
            }
          }
          addData.forEach((element) => {
            pipelines.push({
              id: element.id,
              titulo: element.title,
              tags: element.tags,
              tag_exit: element.tag_exit,
            });
          });
          this.pipelines = pipelines;
        })
        .catch((err) => {});
    },
    removeLead(pipe_id, board) {
      Vue.swal({
        title: "Lead",
        text: `Com esta ação, o lead será removido do CRM escolhido`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Remover lead",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            id: "lead/desassoc",
            lead_id: this.lead.id,
            pipeline_id: pipe_id,
          };
          servicePipeline.createId(data).then(() => {
            let pipe_index = this.pipelines_lead.findIndex((x) => x.id == pipe_id);
            this.pipelines_lead.splice(pipe_index, 1);
            if (this.isIframe) {
              let messageData = {
                type: "removeLead",
                leadId: this.lead.id,
                pipeline: pipe_id,
                lastBoard: board,
              };
              try {
                window.parent.frames["iframeCrm"]?.postMessage(messageData, window.location.origin);
              } catch {}
            }
          });
        }
      });
    },
    fetchLead(id) {
      this.tags = [];
      this.currentModal = "";
      this.loading = true;
      this.company = "";
      if (this.entireLead && this.entireLead.id) {
        this.initFunctions(this.entireLead);
        return;
      }
      serviceLead.read(id).then((resp) => {
        this.initFunctions(resp);
        this.$nextTick(() => {
          this.getMaxWidthSubCard();
          this.ajustTab();
        });
      });
    },
    initFunctions(resp) {
      this.lead = {};
      this.lead = resp;
      this.event_title = `Reunião - ${this.lead.name} X ${this.user.first_name}`;
      this.email = this.lead.email;
      this.hasGoogleConnection();
      // HIDDEN-FEATURE
      // this.fetchCompany();
      this.getLeadProducts(resp.products);
      this.edit.leadscore = resp.leadscore;
      this.edit.name = resp.name;
      this.edit.email = resp.email;
      this.edit.phone = resp.phone;
      this.realNumber = resp.phone;
      this.dateRange1.startDate = resp.follow_up
        ? moment(resp.follow_up).format("YYYY-MM-DD HH:mm:ss")
        : "";
      this.dateRange1.endDate = resp.follow_up
        ? moment(resp.follow_up).format("YYYY-MM-DD HH:mm:ss")
        : "";
      for (let i = 0; i < resp.tags.length; i++) {
        const element = resp.tags[i];
        var result = this.tags_props.find((x) => x.id == element.id);
        if (result) {
          this.tags.push(result);
        }
      }
      this.filterSearchTags = this.tags_props;
      this.filterSearchSellers = this.sellers;
      this.tagSearch = "";
      this.vendedor = this.sellers.find((x) => x.id == resp.user_id);
      if (this.level !== "seller" && this.level !== "gerent_seller") {
        this.vendedorHist = this.vendedor ?? this.user;
      } else {
        this.vendedorHist = this.user;
      }
      this.pre_vendedor = this.sellers.find((x) => x.id == resp.pre_user_id);
      this.loading = false;
      // Endereço
      this.zipCode = this.lead.address?.zip_code ?? "";
      this.countryCode = this.lead.address?.country_code ?? "";
      this.state = this.lead.address?.state ?? "";
      this.city = this.lead.address?.city ?? "";
      this.neighborhood = this.lead.address?.neighborhood ?? "";
      this.street = this.lead.address?.street ?? "";
      this.number = this.lead.address?.number ?? "";
    },
    fetchCompany() {
      // var data = {
      //   id: `list?page=1&per_page=2000`,
      // };
      // serviceCompanies.read(data).then((resp) => {
      //   this.fetched_companies = resp.data;
      //   return;
      // });
      if (this.lead.companies.length != 0) {
        let company_id;
        company_id = this.lead.companies[0].id;
        var data2 = {
          id: `members/${company_id}`,
        };
        serviceCompanies.read(data2).then((resp) => {
          this.company = resp;
          this.editCompany.legal = resp.legal_name;
          this.editCompany.document = resp.document;
        });
      }
    },
    editFollowUp() {
      if (!this.dateRange1.startDate) {
        return;
      }
      var data = {
        id: this.lead.id,
        follow_up: moment(
          this.dateRange1 && this.dateRange1.startDate ? this.dateRange1.startDate : ""
        ).format("YYYY-MM-DD HH:mm:ss"),
      };
      serviceLead.update(data).then(() => {
        this.dateRange1.startDate = moment(this.dateRange1.startDate).format("YYYY-MM-DD HH:mm:ss");
      });
    },
    async fetchPipelineLead(id) {
      this.loadingCRM = true;

      try {
        const resp = await servicePipeline.read(`get/${id}`);

        console.log(resp);
        let pipelines_and_everything = resp.pipelines;
        for (let index = 0; index < pipelines_and_everything.length; index++) {
          var element = pipelines_and_everything[index];
          element.relation = resp.pipeline_relations.find((x) => x.pipeline_id == element.id);
          element.tags = element.tags.map((id_tag) => {
            return (
              resp.tags.find((tag) => tag?.id == id_tag) ?? {
                id: 0,
                name: "Excluída",
                color: "Default",
              }
            );
          });
          element.tags = element.tags.map((tag) =>
            tag.id == element.relation.tag_id
              ? { ...tag, isSelected: true }
              : { ...tag, isSelected: false }
          );
          pipelines_and_everything[index] = element;
        }
        this.pipelines_lead = pipelines_and_everything;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingCRM = false;
      }
    },
    attBoard(pipe_id, tag_id, isAdding, lastBoard) {
      servicePipeline
        .update(`${this.lead.id}/${pipe_id}/${tag_id}`)
        .then(() => {
          if (this.config.changesTag && this.tags.every((tag) => tag.id != tag_id)) {
            this.addTag(this.tags_props.find((tag) => tag.id == tag_id));
          }
          this.currentModal = "";
          if (isAdding) {
            this.fetchPipelineLead(this.lead.id);
          } else {
            this.pipeline = "";
            var arrayNew = this.pipelines_lead;
            if (
              arrayNew.find((pipe) => pipe.id == pipe_id).tags.find((tag) => tag.isSelected == true)
            ) {
              arrayNew
                .find((pipe) => pipe.id == pipe_id)
                .tags.find((tag) => tag.isSelected == true).isSelected = false;
            }
            arrayNew
              .find((pipe) => pipe.id == pipe_id)
              .tags.find((tag) => tag.id == tag_id).isSelected = true;
            this.pipelines_lead = arrayNew;
          }
          if (pipe_id === this.pipeline_id) {
            this.$emit("updateCRM", {
              board: tag_id,
              pipeline: pipe_id,
              lead: this.lead,
              lastBoard: lastBoard,
            });
          } else if (this.isIframe) {
            let messageData = {
              type: "updateCRM",
              pipeline: pipe_id,
              board: tag_id,
              lead: this.lead,
              lastBoard: lastBoard,
            };
            try {
              window.parent.frames["iframeCrm"]?.postMessage(messageData, window.location.origin);
            } catch {}
          }
        })
        .catch(() => {
          this.$grToast.toast("Erro ao alterar board", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    addTag(e) {
      this.currentModal = "";
      this.tags.push(e);
      var data = {
        id: "/assoc",
        leads: [this.lead.id],
        tag_id: e.id,
      };
      serviceTag
        .createId(data)
        .then((resp) => {})
        .catch((err) => {
          this.$grToast.toast("Erro ao adicionar tag", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          let index = this.tags.findIndex((tag) => tag.id == e.id);
          this.tags.splice(index, 1);
        });
    },
    removeTag(e) {
      var idxTag = this.tags.findIndex((x) => x.id == e.id);
      if (idxTag != -1) {
        this.tags.splice(idxTag, 1);
      }
      var data = {
        id: "/desassoc",
        leads: [this.lead.id],
        tag_id: e.id,
      };
      serviceTag
        .createId(data)
        .then(() => {})
        .catch(() => {
          this.$grToast.toast("Erro ao remover tag", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },

    async updateDataLead(data) {
      const { infos, address } = data;

      this.edit = infos;
      this.formAddress = address;

      const addressFilled =  Object.values(address).every(valor => valor !== null && valor !== undefined && valor !== '');

      if((address.zipCode.length && address.zipCode.length < 9) || (address.zipCode.length && !addressFilled)){
        this.$grToast.toast("Preencha todos os campos de endereço", {
          title: "Cadastro manual",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      await this.editLead();
      await this.updateAddress();
    },

    editLead() {
      if (!this.edit.name) {
        this.$grToast.toast("Adicione um nome ao lead", {
          title: "CRM",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (!this.edit.email && !this.lead.email && !this.edit.phone) {
        this.$grToast.toast("O lead deve ter um email ou um número de telefone associado", {
          title: "CRM",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (this.edit.score < 0) {
        this.$grToast.toast("O score deve ser maior que 0", {
          title: "CRM",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (this.edit.leadscore != this.lead.leadscore) {
        Vue.swal({
          title: "Lead",
          text: `Ao editar o score do lead, automações podem ser disparadas`,
          type: "danger",
          showCancelButton: true,
          confirmButtonText: "Continuar",
          cancelButtonText: "Cancelar",
          customClass: "sweet-container",
          confirmButtonClass: "button button-primary mt-3 mb-3",
          cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
          buttonsStyling: false,
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            var data = {
              id: this.lead.id,
              email: this.edit.email ? this.edit.email : "",
              name: this.edit.name,
              phone: this.edit.phone,
              leadscore: this.edit.leadscore,
              leadscore_type: "manual",
            };
            serviceLead.update(data).then((resp) => {
              // this.fetchLead(this.lead.id);
              this.lead.name = data.name;
              this.lead.email = data.email;
              this.lead.phone = data.phone;
              this.lead.leadscore = data.leadscore;
              this.currentModal = "";
            });
          } else {
            return;
          }
        });
      } else {
        var data = {
          id: this.lead.id,
          email: this.edit.email ? this.edit.email : "",
          name: this.edit.name,
          phone: this.edit.phone,
        };
        serviceLead.update(data).then((resp) => {
          this.$grToast.toast("Lead alterado com sucesso", {
            title: "CRM",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.lead.name = data.name;
          this.lead.email = data.email;
          this.lead.phone = data.phone;
          this.currentModal = "";
        });
      }
    },
    editVendedor(vendedor, type) {
      let data = {
        id: "assoc",
        leads: [this.lead.id],
      };

      if (type === "user") {
        data.user_id = vendedor;
      } else if (type === "pre_user") {
        data.pre_user_id = vendedor;
      }

      serviceSellerAutomation
        .createId(data)
        .then(() => {
          this.currentModal = "";
          // this.fetchLead(this.lead.id);
          if (type === "user") {
            this.lead.user = this.sellers.find((x) => x.id == vendedor);
            this.lead.user_id = vendedor;
            this.vendedor = this.sellers.find((x) => x.id == vendedor);
          } else {
            this.lead.pre_user = this.sellers.find((x) => x.id == vendedor);
            this.lead.pre_user_id = vendedor;
            this.pre_vendedor = this.sellers.find((x) => x.id == vendedor);
          }
        })
        .catch(() => {
          this.$grToast.toast(`Erro alterar ${type === "user" ? "vendedor" : "pré-vendedor"}`, {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    removerVendedor(type) {
      let data = {
        id: "desassoc",
        leads: [this.lead.id],
        type: type,
      };
      serviceSellerAutomation
        .createId(data)
        .then(() => {
          if (type === "user") {
            this.vendedor = null;
            this.lead.user = null;
            this.lead.user_id = null;
          } else {
            this.pre_vendedor = null;
            this.lead.pre_user = null;
            this.lead.pre_user_id = null;
          }
          this.currentModal = "";
          // this.fetchLead(this.lead.id);
        })
        .catch(() => {
          this.$grToast.toast(
            `Erro ao desassociar ${type === "user" ? "vendedor" : "pré-vendedor"}`,
            {
              title: "Lead",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
        });
    },
    closeModal() {
      this.$grModal.hide("visualizar-lead", this);
    },
    execHiddenModal() {
      if (!this.inList) {
        this.updateLeadPipeline();
      }
    },
    createAdd() {
      if (!this.editAdicional.name || !this.editAdicional.value) {
        this.$grToast.toast("Preencha os dados para adicionar uma chave", {
          title: "Lead",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (this.editAdicional.name == "photo-lead" || this.editAdicional.name == "pipeline_view") {
        this.$grToast.toast("Nome inválido", {
          title: "Lead",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      let data = {
        lead_id: this.lead.id,
        meta_key: this.editAdicional.name,
        meta_value: this.editAdicional.value,
      };
      serviceAdicional
        .create(data)
        .then((resp) => {
          let newItem = {
            id: resp.id,
            meta_key: resp.meta_key,
            meta_value: resp.meta_value,
          };
          this.lead.metas.push(newItem);
        })
        .catch((err) => {
          if (err.response.data.meta_key[0] === "The meta key has already been taken.") {
            this.$grToast.toast("Essa meta já existe", {
              title: "Lead",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return;
          }
          this.$grToast.toast("Erro ao criar campo adicional", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    saveAdd(adicional_id, name, value) {
      if (!name || !value) {
        this.$grToast.toast("Preencha os dados para adicionar uma chave", {
          title: "Lead",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      let data = {
        id: adicional_id,
        lead_id: this.lead.id,
        meta_key: name,
        meta_value: value,
      };
      serviceAdicional
        .update(data)
        .then(() => {
          this.lead.metas.find((adicional) => adicional.id == adicional_id).meta_key = name;
          this.lead.metas.find((adicional) => adicional.id == adicional_id).meta_value = value;
        })
        .catch((err) => {
          this.$grToast.toast("Erro ao atualizar campo adicional", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    deleteAdd(id) {
      Vue.swal({
        title: "Lead",
        text: `Deseja deletar o campo escolhido?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            id: `${this.lead.id}/${id}`,
          };
          serviceAdicional
            .destroy(data)
            .then(() => {
              let adicionalIndex = this.lead.metas.findIndex((adicional) => adicional.id == id);
              this.lead.metas.splice(adicionalIndex, 1);
            })
            .catch(() => {
              this.$grToast.toast("Erro ao deletar campo adicional", {
                title: "Lead",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
    fetchTimeLine(page) {
      serviceLead.read(`/timeline/${this.lead.id}?page=${page ?? 1}`).then((resp) => {
        var data = resp.data;
        if (data.length === 0) {
          this.count = "stop";
        }
        if (this.timeline.length === 0 || page === 1) {
          this.timeline = data;
          this.count = 1;
        } else {
          this.timeline = this.timeline.concat(data);
        }
      });
    },
    async fetchWpp(id) {
      this.loadingMensagem = true;
      await serviceWhatsapp.read(`get/${id}`).then((resp) => {
        this.wpp = resp;
        this.loadingMensagem = false;
      });
    },
    getNextPage() {
      const listElm = document.querySelector("#infinite-list");
      if (listElm) {
        listElm.addEventListener("scroll", () => {
          if (
            listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 500 &&
            this.count != "stop"
          ) {
            this.count++;
            this.fetchTimeLine(this.count);
          }
        });
      }
    },
    cancelMessage(id, index) {
      Vue.swal({
        title: "Lead",
        text: `Deseja cancelar a mensagem?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Cancelar",
        cancelButtonText: "Fechar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            id: `cancel`,
            msg_id: [id],
          };
          serviceWhatsapp
            .createId(data)
            .then(() => {
              this.wpp[index].status = "cancelled";
            })
            .catch(() => {
              this.$grToast.toast("Erro ao cancelar mensagem", {
                title: "Lead",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
    resendMessage(id) {
      Vue.swal({
        title: "Lead",
        text: `Deseja reenviar a mensagem?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Reenviar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var data = {
            id: `resend`,
            msg_id: id,
          };
          serviceWhatsapp
            .createId(data)
            .then(() => {
              this.$grToast.toast("Mensagem reenviada com sucesso", {
                title: "Lead",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.fetchWpp(this.lead.id);
            })
            .catch(() => {
              this.$grToast.toast("Erro ao reenviar mensagem", {
                title: "Lead",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
    returnStatusMessage(status) {
      switch (status) {
        case "preparing": {
          return "aguardando";
          break;
        }
        case "sent": {
          return "enviada";
          break;
        }
        case "error": {
          return "erro";
          break;
        }
        case "cancelled": {
          return "cancelado";
          break;
        }
        case "queue": {
          return "Na fila";
          break;
        }
      }
    },
    verifyDate(item) {
      let currentDate = moment();
      let isBeforeOrEqual = moment(item.date_added).isSameOrBefore(currentDate);
      if (isBeforeOrEqual && item.status == "preparing") {
        return isBeforeOrEqual;
      }
      return false;
    },
    addNota() {
      if (!this.nota) {
        this.$grToast.toast("Escreva algo para adicionar uma nota", {
          title: "Lead",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      let simulate = {
        content: this.nota,
        created_at: moment(),
        id: `tempID`,
        lead_id: this.lead.id,
        updated_at: moment(),
        user_id: this.user.id,
      };
      this.lead.notes.push(simulate);

      let data = {
        id: ``,
        content: this.nota,
        lead_id: this.lead.id,
      };
      this.nota = "";

      serviceNota
        .create(data)
        .then((resp) => {
          this.lead.notes[this.lead.notes.length - 1].id = resp.note.id;
        })
        .catch((err) => {
          this.lead.notes.pop();
          this.$grToast.toast("Erro ao adicionar nota, tente novamente mais tarde", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },

    toastInactiveLead() {
      this.$grToast.toast("Não é possível enviar mensagem para este lead, pois ele está inativo.", {
        title: "Lead inativo",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },

    agendarMensagem() {
      if (this.lead.status !== "active") return this.toastInactiveLead();

      if (!this.dateRange2.startDate || !this.mensagem) {
        this.$grToast.toast("Por favor, preencha os campos corretamente", {
          title: "CRM",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (this.lead.phone == "") {
        this.$grToast.toast("O lead precisa de um número de telefone", {
          title: "Lead",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      var data = {
        id: `send/${this.lead.id}`,
        message: this.mensagem,
        date_added: moment(this.dateRange2.startDate).format("YYYY-MM-DD HH:mm:ss"),
      };
      serviceWhatsapp
        .createId(data)
        .then((resp) => {
          this.loading = false;
          if (resp[0] == "Mensagem agendada com sucesso!") {
            this.fetchWpp(this.lead.id);
            this.$grToast.toast("Mensagem agendada com sucesso", {
              title: "Lead",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.mensagem = "Olá, {{nome}}! Tudo bem?";
            this.renderDate = false;
            this.dateRange2.startDate = undefined;
            this.dateRange2.endDate = undefined;
            setTimeout(() => {
              this.renderDate = true;
            }, 50);
          }
        })
        .catch(() => {
          this.$grToast.toast("Erro ao agendar mensagem", {
            title: "Lead",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    getProduct(page = 1) {
      if (this.isIframe && this.productsData) {
        this.products = this.productsData;
        return;
      }
      var data = {
        page: page,
        id: `list?page${page}`,
        per_page: 1000,
      };
      serviceProduct
        .createId(data)
        .then((resp) => {
          this.products = this.products.concat(resp.data);
        })
        .catch((err) => {});
    },
    getLeadProducts(resp_lead_products) {
      if (this.products.length) {
        var lead_products = resp_lead_products;
        for (let i = 0; i < lead_products.length; i++) {
          lead_products[i].product = this.products.find((x) => x.id == lead_products[i].product_id);
        }
        if (lead_products.length == this.products.length) {
          this.showContainerAddProduct = false;
        }
        this.lead_products = lead_products;
      }
    },
    salvarDesconto(e) {
      var item = e;
      // Atualizar
      if (item.net_value || item.net_value === 0) {
        var data = {
          id: "assoc",
          leads: [this.lead.id],
          net_value: this.editProduct.finalPrice,
          discount: this.editProduct.discount,
          product_id: item.product.id,
          quantity: this.editProduct.quantity,
        };
        if (item.discount <= 100) {
          serviceProduct
            .createId(data)
            .then(() => {
              const indexProduct = this.lead_products.findIndex(
                (x) => x.product_id == item.product.id
              );
              let new_lead_products = this.lead_products;
              new_lead_products[indexProduct].net_value = data.net_value;
              new_lead_products[indexProduct].discount = data.discount;
              new_lead_products[indexProduct].quantity = data.quantity;
              this.lead_products = new_lead_products;
              return;
            })
            .catch(() => {
              this.$grToast.toast("Erro ao salvar o produto", {
                title: "Lead",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              return;
            });
        }
        return;
      }

      var data = {
        id: "assoc",
        leads: [this.lead.id],
        net_value: this.valorLiquido,
        discount: this.valorPorcentagem,
        product_id: this.selectProduct.id,
        quantity: this.quantity,
      };

      if (this.valorPorcentagem <= 100 && this.selectProduct) {
        serviceProduct
          .createId(data)
          .then(() => {
            let new_product = {
              discount: this.valorPorcentagem,
              net_value: this.valorLiquido,
              product: item,
              product_id: item.id,
              quantity: data.quantity,
            };
            this.lead_products.push(new_product);
            if (this.lead_products.length == this.products.length) {
              this.showContainerAddProduct = false;
            }
            this.showAddProduct = false;
            (this.valorLiquido = 0), (this.valorPorcentagem = 0), (this.selectProduct = "");
          })
          .catch(() => {
            this.$grToast.toast("Erro ao salvar produto", {
              title: "Lead",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .finally(() => {
            this.quantity = 1;
          });
      } else {
        this.$grToast.toast("Selecione um produto", {
          title: "Lead",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    desassociarProduto(id) {
      Vue.swal({
        title: "Lead",
        text: `Deseja desassociar o produto?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Desassociar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          serviceProduct
            .delete(`desassoc/${this.lead.id}/${id}`)
            .then(() => {
              this.showContainerAddProduct = true;
              let indexProduct = this.lead_products.findIndex((x) => x.product_id == id);
              this.lead_products.splice(indexProduct, 1);
            })
            .catch(() => {
              this.$grToast.toast("Erro ao desassociar o produto", {
                title: "Lead",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            });
        }
      });
    },
    getMetaInitial(user) {
      var result = this.sellers.find((x) => x.id == user);
      if (result) {
        var name = result?.first_name || "";
        var sobrenome = result?.last_name || "";
      }
      if (!name && !sobrenome) {
        return result?.email.substring(0, 2);
      }
      if (!name) {
        return sobrenome.substring(0, 2);
      }
      if (!sobrenome) {
        return name.substring(0, 2);
      }
      return name[0] + sobrenome[0];
    },
    getMetaInitialLead(user) {
      if (user) {
        var name = user.split(" ")[0];
        var sobrenome = user.split(" ")[1] ?? undefined;
        if (!name && !sobrenome) {
          return "";
        }
        if (!sobrenome) {
          return name.substring(0, 2);
        }
        return name[0] + sobrenome[0];
      }
      return;
    },
    initialsProduct(user) {
      if (user) {
        var name = user.split(" ")[0];
        var sobrenome = user.split(" ")[1] ?? undefined;
        if (!name && !sobrenome) {
          return "";
        }
        if (!sobrenome) {
          return name.substring(0, 2);
        }
        return name[0] + sobrenome[0];
      }
      return "??";
    },
    removeFollow() {
      var data = {
        id: this.lead.id,
        follow_up: null,
      };
      serviceLead
        .update(data)
        .then(() => {
          this.renderDate = false;
          this.dateRange1.startDate = undefined;
          this.dateRange1.endDate = undefined;
          setTimeout(() => {
            this.renderDate = true;
          }, 50);
        })
        .catch(() => {
          this.$grToast.toast("Falha ao remover follow-up", {
            title: "CRM",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    getListMessage() {
      let data = {
        id: `/getmessagelead/${this.lead.id}`,
        page: 1,
      };
      serviceLeadMessage
        .createId(data)
        .then((response) => {
          this.listLogMessage = response.data;
          this.loadingHist = false;
        })
        .catch((err) => {
          this.listLogMessage = [];
          this.loadingHist = false;
        });
    },
    openModalMessage(data) {
      this.listMessageInfo = [];
      this.$bvModal.show("Modal-Lead-Message", data.id);
      for (let i = 0; i < data.messages.length; i++) {
        const element = data.messages[i];
        if (element.type == "message-in") {
          const date = element.date;
          const regex = /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/;
          const result = date.match(regex);

          if (result && result.length >= 4) {
            const time = `${result[4]}:${result[5]}`;
            const date = `${result[1]}/${result[2]}/${result[3]}`;
            this.otherDateLog = date;
            this.otherTimeLog = time;
          }

          this.otherMessageLog = element.message;
          this.otherNameLog = element.type;

          const obj = {
            contact: element.contact,
            image: element.image,
            owner: this.otherNameLog,
            message: this.otherMessageLog,
            date: this.otherDateLog,
            time: this.otherTimeLog,
            audio: element.audio,
          };

          this.listMessageInfo.push(obj);
        } else {
          const date = element.date;
          const regex = /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/;
          const result = date.match(regex);

          if (result && result.length >= 4) {
            const time = `${result[4]}:${result[5]}`;
            const date = `${result[1]}/${result[2]}/${result[3]}`;
            this.myDateLog = date;
            this.myTimeLog = time;
          }
          this.myMessageLog = element.message;
          this.myNameLog = element.type;

          const obj = {
            contact: element.contact,
            image: element.image,
            owner: this.myNameLog,
            message: this.myMessageLog,
            date: this.myDateLog,
            time: this.myTimeLog,
            audio: element.audio,
          };

          this.listMessageInfo.push(obj);
        }
      }
    },
    redirectPhone(telefone) {
      if (this.isIframe) {
        let data = {
          type: "openLead",
          href: `https://wa.me/${telefone.replace("+", "")}`,
        };
        this.$emit("returnPostMensage", data);
        return;
      }
      var url = `https://api.whatsapp.com/send?phone=${telefone.replace("+", "")}`;
      window.open(url, "_blank");
    },
    upgradePlan() {
      $crisp.push(["set", "session:segments", [["financeiro"]]]);
      $crisp.push(["do", "chat:open"]);
      $crisp.push([
        "do",
        "message:send",
        ["text", `Quero fazer um upgrade no meu plano e ter acesso ao CRM.`],
      ]);
    },
    async getGreennLeadInformations(leadId) {
      await serviceGreenn.read({ id: `lead/${leadId}` }).then((resp) => {
        if (!Object.keys(resp)) {
          return;
        }

        this.greennLeadOffers = resp.offers;
        this.formattedDataPurchase(resp);
      });
    },
  },
  created() {
    this.tags_props = this.tags_props;
    if (this.isIframe) {
      this.level = this.userLevelData;
      this.recursos = this.recursosData;
      this.user = this.userData;
      this.tenantSubdomain = this.subdomainData;
    } else {
      this.user = this.$store.getters.user.user;
      this.level = this.$store.getters.user.user.level;
      this.recursos = this.$store.getters.recursos;
      this.tenantSubdomain = this.$store.getters.user.user.tenant_subdomain;
    }
    setTimeout(() => {
      this.fetchPipelines();
      this.getProduct();
    }, 200);
  },
};
</script>

<style lang="scss" scoped>
.sub-card-hist {
  cursor: pointer;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 12px;
  height: fit-content;
  transition: all 0.3s;
  position: relative;
  &:hover {
    background-color: #efefef4d;
  }
  &:last-child {
    margin-bottom: 0;
  }

  .sellers-name {
    max-width: 100%;
    overflow: hidden;
    color: var(--gray03);
    font-size: 12px;
    line-height: 1.2;
  }
}
.lead-search2 {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  input {
    width: 100%;
    height: 30px !important;
    margin: 0 !important;
    padding-left: 30px !important;
  }
  img {
    width: 17px !important;
    height: 17px !important;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.AnimationRight-enter-active {
  animation: example 0.2s;
  animation-timing-function: ease-out;
}

.AnimationRight-leave-active {
  animation: example 0.2s reverse;
  animation-timing-function: ease-in;
}
.initial {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background-color: var(--greenn2);
  border: 2px solid var(--greenn);
  text-align: center;
  font-size: 23px;
  color: var(--greenn);
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.small {
  height: 35px !important;
  width: 35px !important;
  font-size: 16px !important;
}
.all-white {
  color: white !important;
  background-color: #fff4 !important;
  border-color: white;
}
.log-header {
  align-items: center;
  img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 100%;
  }
  padding: 10px 20px;
  background-color: var(--greenn);
  color: var(--white-light);
  .user-info {
    display: block;
    width: 100%;
    .name-hist {
      font-size: 15px;
      font-weight: 500;
      display: block;
      color: white;
    }
    .email-hist {
      line-height: 1.2;
      font-size: 12px;
      color: white;
      display: block;
      font-weight: 300;
    }
  }
}

.user-header {
  position: relative;
  display: grid;
  grid-template-columns: 35px calc(100% - 65px) 10px;
  width: 100%;
  gap: 10px;
  border-right: 1px solid #dbdbdb;
  cursor: pointer;
  .select {
    background-color: white;
    width: 10px;
    height: 6px;
    transition: all 0.3s;
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  }

  .seller-select {
    position: absolute;
    width: 90%;
    margin-left: 5%;
    top: 90%;
    left: 0;
    background-color: #fff;
    z-index: 10;
    border-radius: 10px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
    z-index: 1000;
  }

  span {
    max-width: calc(100%);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
    display: block;
    margin-bottom: 0;
  }
}
// HIDDEN-FEATURE
.in-progress {
  width: 100%;
  height: 100%;
  display: grid;
  align-content: center;
  justify-items: center;
  h3 {
    text-align: center;
    font-size: 18px;
  }
}
.activities-line {
  justify-content: space-between;
  position: relative;
  * {
    transition: all 0.5s;
  }
  .activity-dot {
    border-radius: 100%;
    aspect-ratio: 1;
    height: 26px;
    background-color: transparent;
    border: 3px solid var(--greenn);
    position: relative;
    z-index: 1;
  }
  .incomplete {
    background-color: var(--white-light);
    filter: saturate(0.1) brightness(2);
  }
  .progress {
    background-color: var(--greenn-light);
    box-shadow: inset 0 0 0 3px var(--white-light);
  }
  .complete {
    background-color: var(--greenn);
    &::after {
      content: "✓";
      position: absolute;
      top: 50%;
      left: 50%;
      color: var(--white-medium);
      transform: translate(-50%, -50%);
    }
  }
  .line {
    overflow: hidden;
    margin: 4px 11px;
    width: 5px;
    height: calc(100% - 34px);
    border-radius: 5px;
    position: relative;
    background-image: linear-gradient(
      0deg,
      transparent 0px,
      transparent 2px,
      var(--greenn) 2px,
      var(--greenn) 8px,
      transparent 8px,
      transparent 10px
    );
    background-size: 10px 0px;
    &::after {
      content: "";
      border-radius: 5px;
      display: block;
      height: 0%;
      width: 100%;
      background-color: var(--greenn);
      transition: all 0.5s;
    }
  }
  .complete-line {
    background-size: 10px 10px;
    &::after {
      height: 100%;
    }
  }
  .progress-line {
    background-size: 10px 10px;
  }
  .incomplete-line {
    filter: saturate(0.1) brightness(2);
    background-color: var(--greenn);
  }
}

.activity-content {
  width: 100%;
  padding: 0 10px 10px;
  .title {
    margin: 3px 0 5px;
    padding: 0;
    font-size: 16px;
  }
  .sub-card {
    cursor: pointer;
    max-height: 100px;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    * {
      opacity: 0.4;
    }
    width: 100%;
  }
  // .sub-card:not(.active-card) {
  //   &::after {
  //     content: '';
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 80px;
  //     opacity: 1;
  //     background-image: linear-gradient(180deg, transparent, white);
  //     transition: opacity 0.3s;
  //   }
  // }
  .active-card {
    max-height: 300px !important;
    overflow-y: auto !important;
    * {
      opacity: 1 !important;
    }
  }
}
.activity-controls {
  overflow: hidden;
  transition: all 0.3s;
  -webkit-user-drag: none;
  > div {
    display: flex;
    margin-bottom: 10px;
    transition: all 0.3s;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid var(--greenn);
    cursor: pointer;
    font-size: 14px;
    &:hover {
      background-color: var(--greenn2);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  img {
    height: 20px;
    cursor: pointer;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.loading {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

a {
  text-decoration: none !important;
}
.messages-cards {
  .sub-card {
    background-color: var(--greenn2);
    transition: all 0.3s;
    width: 100%;
    display: grid;
    position: relative;
    // gap: 10px;
    // grid-template-columns: calc(100% - 30px) 20px;
    border-color: transparent;
    &:hover {
      background-color: var(--greenn-transparent);
      border-color: var(--greenn);
    }
    // div:last-child {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    // }
    p {
      color: var(--gray03);
    }
  }
}
.box-total > div > p {
  color: var(--greenn);
}
.card-members {
  display: flex;
  margin-bottom: 0 !important;
  align-items: center;
  gap: 15px;
  max-width: calc(100% - 7px);
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    background-color: var(--white-light);
  }

  .lead-name {
    color: var(--gray02);
    font-weight: 600;
  }

  .leader-after {
    position: relative;
    margin-bottom: 19px !important;

    &::after {
      content: "Líder";
      font-size: 10px;
      background-color: var(--greenn);
      color: white;
      padding: 2px 5px;
      line-height: 1.1;
      border-radius: 3px;
      position: absolute;
      margin: 63px -3px 0 !important;
    }
  }

  .seller-no-ft {
    width: 45px;
    height: 45px;
    margin: 0;

    h3 {
      font-size: 20px;
    }
  }

  .lead-texts {
    max-width: calc(100% - 60px);
    overflow: hidden;

    p {
      margin: 0 !important;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.company-name {
  font-size: 20px;
  color: var(--gray02);
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  line-height: 1.2;
  margin-bottom: 0.4rem;
}

// .non-active {
// max-width: 0 !important;
// }

.tab-title {
  overflow: hidden;
  transition: all 0.6s ease-out;
  padding: 0 !important;
  margin: 3px 0 0 0 !important;
}

.img-tab-title {
  padding: 0 5px;
}

.lead-data {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
  .lead-picture {
    // aspect-ratio: 1;
    border-radius: 100%;
    overflow: hidden;
    width: 5vw;
    min-width: 60px;
    width: 60px;
    min-height: 60px;
    height: 60px;
    background-size: cover;
    background-position-x: center;
    background-color: var(--greenn);

    * {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .lead-initial {
      font-size: 30px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 600;
    }

    .add-img-lead {
      display: flex;
      border-radius: 100%;
      position: absolute;
      top: 3px;
      min-width: 60px;
      width: 60px;
      min-height: 60px;
      height: 60px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      background-color: #ededf0a2;
      opacity: 0;
      transition: opacity 0.4s ease;

      span {
        font-size: 45px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          fill: #141414;
          width: 19px;
          height: 21px;
        }
      }
    }

    .add-img-lead:hover {
      opacity: 1;
    }
  }

  .lead-texts {
    width: -webkit-fill-available;
    width: -moz-available;
    max-width: calc(100% - 73px);
    .lead-phone {
      display: flex;
      transition: all 0.3s;
    }
    .lead-phone-hover:hover {
      cursor: pointer;
      color: var(--greenn);
    }
  }
  .lead-score {
    font-size: 16px !important;
    font-weight: 400;
    color: var(--greenn) !important;
  }

  .lead-name {
    font-size: 18px !important;
    font-weight: 600;
    color: #141414;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 25px;
    word-break: break-all;
  }

  .deg {
    font-size: 16px;
    color: var(--greenn) !important;
    display: flex;
    height: fit-content;
    margin: 0 3px;
  }

  .dot {
    margin: -3px 8px 0;
  }
}

.edit-lead {
  margin-top: 0.75rem;

  label {
    margin: 0 !important;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}

.drop-bottom,
.drop-top {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 15px;
  width: 285px;
  position: absolute;
  box-shadow: 2px 2px 10px 1px var(--white-medium);
  background-color: var(--white);
  z-index: 2;
  left: 0;
  margin: 0;
  user-select: none;
}
.drop-top {
  top: -8px !important;
  transform: translateY(-100%) !important;
}
.drop-right {
  right: 0 !important;
  left: unset !important;
  transform: unset;
}
.drop-bottom {
  top: calc(100% + 8px);
}
.sub-card {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 12px;
  position: relative;

  > .drop-bottom,
  > .drop-top {
    width: 95%;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
  }
  > .drop-top {
    transform: translateY(-100%) translateX(-50%) !important;
  }
  .button-right button {
    width: 50%;
  }

  .vendedores {
    transition: all 0.3s;

    &:hover {
      background-color: #efefef4d;
    }
  }

  .sellers-name {
    max-width: 100%;
    overflow: hidden;
  }
}

.lead-tags {
  .lead-tag-name {
    margin: 0px;
    padding: 0px;
    max-width: 220px;
    overflow: hidden;
    height: 15px;
    text-overflow: ellipsis;
    border-radius: 0;
  }

  div {
    width: 100%;
  }

  span {
    font-size: 12px;
    color: white;
    text-transform: uppercase;
    padding: 0px 8px;
    margin: 0 4px 2px 0;
    border-radius: 5px;
    width: fit-content;
    display: inline-block;
    cursor: default;
    transition: all 0.3s;

    .delete-tag {
      padding: 0 0 0 6px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        filter: drop-shadow(0.5px 0.5px 0px white) drop-shadow(-0.5px 0.5px 0px white);
      }
    }
  }

  .add-tag {
    color: var(--greenn);
    border: 1px solid var(--greenn) !important;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: var(--greenn-transparent);
    }
  }

  .info_color {
    background: var(--bluee);
  }

  .danger_color {
    background: var(--red);
  }

  .warning_color {
    background: var(--orange);
  }

  .primary_color {
    background: var(--greenn-light);
  }

  .success_color {
    background: var(--greenn);
  }

  .default_color {
    background: var(--gray01);
  }
}

#tags-all {
  max-height: 250px;
  overflow-y: auto;

  input {
    height: 25px !important;
    padding-left: 32px !important;
  }

  .searchIcon {
    position: absolute;
    height: 15px;
    top: 24px;
    left: 24px;
  }

  .container-tag:hover {
    filter: brightness(0.8);
  }

  .container-tag {
    cursor: pointer;

    * {
      cursor: pointer;
    }
  }
}

#google-calendar label {
  margin: 0;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 5px;
  background: #ddd;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  margin-top: -6px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--white-dark);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

input::-webkit-datetime-edit-hour-field:focus,
input::-webkit-datetime-edit-minute-field:focus,
input::-webkit-datetime-edit-second-field:focus,
input::-webkit-datetime-edit-ampm-field:focus {
  background-color: var(--greenn-transparent);
}

.btn-follow:hover {
  background-color: var(--greenn-transparent);
}

.lead-aside {
  max-height: calc(94vh - 89px);
  min-height: calc(94vh - 89px);
  border-right: 1px solid var(--white-medium) !important;
  padding: 20px 30px 20px 0;
  width: 350px;
  min-width: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
  user-select: none;
  -webkit-user-drag: none;

  * {
    -webkit-user-drag: none;
    user-select: none;
  }
}

.campo-desconto {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 15px;
  margin: 0px;

  input {
    margin: 0 !important;
  }
}

.x {
  margin-bottom: 13px;
  margin-left: 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.cancelled {
  color: red;
  background-color: #ff00002b;
}

.error {
  color: var(--gray02);
  background-color: var(--white-medium);
}

.preparing {
  background-color: rgba(246, 190, 41, 0.1) !important;
  color: #f6be29 !important;
}

.sent {
  background-color: var(--greenn2);
  color: var(--greenn);
}

.product-gap {
  display: flex;
  align-items: center;
  gap: 10px;
}

p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}

p + p {
  margin-top: 5px;
}

strong {
  font-size: 12px;
  color: #acacac;
}

.status-button {
  display: flex;
  gap: 10px;
}

// strong:hover {
//   color: var(--greenn);
// }
.active {
  color: var(--greenn);
}

.message-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h3 {
  font-size: 1rem;
  padding-top: 2px;
  color: var(--bluee);
}

.box-total {
  margin-top: 20px;
  transition: 0.3s;
}

.box-total:hover {
  transform: translate3d(0, -3px, 0);
}

input,
select {
  border: 1px solid #d6d6d6;
  padding: 15px;
  color: var(--gray02) !important;
  width: 100%;
  outline: none;
  border-radius: 4px;
  transition: 0.2s ease;
}

.wrapper {
  border: none !important;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  grid-auto-rows: minmax(100%, 100%);
  position: relative;
  > div {
    padding: 25px 25px 0;
    max-height: 100%;
    overflow-y: scroll;
  }
}

.grid-pipeline {
  gap: 25px;
}

label {
  gap: 10px !important;
  display: flex !important;
  color: var(--gray01);
  font-weight: 600;
  margin: 20px 0 10px 0;
  font-size: 14px;
}

.cursor {
  cursor: pointer;
}

.button-right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  gap: 10px;

  button {
    border-radius: 10px;
  }
}

.temperature-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.temperature {
  font-size: 36px;
  font-weight: normal;
  cursor: pointer;
  transition: 0.5s ease;
}

.selected a {
  background: #e5f9f6;
  border-bottom: 1px solid #008e7b !important;
  color: #008e7b !important;
}

.perfil {
  width: 50px;
  height: 50px;
  background-color: gray;
  border-radius: 50%;
}

.user-icon {
  width: 35px;
  margin-top: 11px !important;
}

.title {
  color: var(--greenn);
  font-weight: 600;
  font-size: 13px;
  margin: 10px 0px 5px;
  text-transform: uppercase;
  user-select: none;
  -webkit-user-drag: none;
}

.btn-follow {
  margin-top: 0;
  font-weight: bold;
  transition: 0.5s ease;
  background: #e5f9f6;
  color: var(--greenn);
  text-transform: uppercase;
  font-size: 12px;
  padding: 8px !important;
  border-radius: 8px;
  border: none;
  height: 40px;
  transition: all 0.3s;
  text-align: center;
}

.temp-input {
  width: 100px;
}

.gray {
  color: silver;
  font-size: 14px;
  font-weight: normal;
}

.data-body {
  display: flex;
  align-items: center;
  gap: 15px;
}

.vendedor {
  position: relative;
  border-bottom: 1px solid #d6d6d6;
}

.vendedor::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--greenn);
  border-radius: 50%;
  top: 10px;
  right: 5px;
}

.adicional-campo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 5px;
}

.adicional-campo img {
  width: 35px !important;
}

.adicional-campo input {
  margin-bottom: 0px !important;
}

.time-line {
  padding: 5px 20px;
  overflow: hidden;
}

.img-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 16px;
  left: 17px;
  z-index: 3;
}

.pointer {
  top: 16px !important;
  left: 21px !important;
}

.clipe {
  cursor: pointer;
  margin: 15px 0px 0px 8px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.items {
  border: none;
  border-radius: 10px;
  padding: 15px;
  overflow: auto;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.lead-notes {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  background-color: var(--greenn2);
  border-radius: 0 10px 10px 0;
  cursor: default;

  .body-nota {
    margin-bottom: 15px;
    position: relative;
    margin-right: -10px;

    > * {
      padding-right: 20px;
    }

    &:hover {
      .lead-note::after {
        content: "✕";
        width: 15px;
        right: -5px;
      }
    }
  }

  .lead-note-date {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    text-align: end;
    color: var(--gray05);
  }

  .lead-note {
    position: relative;
    text-align: end;
    word-break: break-word;
    color: var(--gray02);

    &::after {
      content: "";
      transition: all 0.3s;
      position: absolute;
      width: 3px;
      height: calc(100% - 5px);
      top: 0;
      right: 7px;
      background: var(--greenn);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .delete-note-ghost {
    width: 15px;
    right: -5px;
    position: absolute;
    z-index: 10;
    height: calc(100%);
    top: 0;
    background-color: transparent;
    cursor: pointer;
  }

  #add-nota {
    border-bottom: 1px solid var(--white-dark);
    display: flex;
    margin-bottom: 25px;

    textarea {
      background-color: transparent;
      border: none !important;
      margin: 0 !important;
      text-align: end;
      width: 100% !important;
      resize: none;
      padding-bottom: 0 !important;
      margin-bottom: 10px !important;
      transition: all 0.3s;
    }

    .is-valid {
      padding-right: 20px !important;
      background-image: none !important;
    }

    span {
      font-size: 34px;
      color: var(--greenn);
      margin-top: 15px;
    }
  }
}

// .crm-cards {
//   container-type: inline-size;
//   container-name: crm-cards;
// }

// @container crm-cards (max-width: 600px) {
//   grid-template-columns: 1fr 1fr 1fr !important;
// }
.products-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-auto-flow: dense;
  gap: 15px;

  .product-card {
    display: grid;
    grid-template-columns: 47px calc(100% - 97px) 20px;
    gap: 15px;
    align-items: center;
    height: fit-content;

    .product-photo {
      position: relative;
      width: 47px;
      min-width: 47px;
      height: 47px;
      border-radius: 10px;
      background-size: cover;
      border: 1px solid var(--white-medium);
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 20px;
        color: var(--greenn);
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        user-select: none;
      }

      .product-quantity {
        position: absolute;
        right: -12px;
        top: -9px;
        color: var(--greenn);
        font-size: 13px;
      }
    }

    .product-infos {
      width: -webkit-fill-available;
      width: -moz-available;
      user-select: none;
      -webkit-user-drag: none;
      display: flex;
      flex-direction: column;
    }

    p {
      color: var(--gray02);
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
}

.gren-card {
  max-width: 450px;
  border: 1px solid var(--greenn2);
  border-radius: 10px !important;
  background-color: var(--white);
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  transition: 0.3s ease;

  // cursor: pointer;
  p,
  textarea {
    color: var(--gray02);
    max-height: 150px !important;
    overflow-y: auto !important;
    font-size: 12px !important;
    width: 100% !important;
  }

  textarea {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100% !important;
    padding: 5px !important;
  }
}

.data-mensagem {
  font-size: 14px;
  font-weight: 600;
  color: var(--gray01);
  font-size: 12px;
  margin: 0;
}

.data-nota {
  font-size: 14px;
  font-weight: 600;
  color: var(--gray01);
  font-size: 12px;
  margin: 0 0 10px 0;
  position: relative;
}

.user {
  color: black;
  font-weight: bold;
  font-size: 12px;
  text-align: right;
}

.message-status {
  padding: 5px 12px;
  border-radius: 4px;
  width: fit-content;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.seller-no-ft {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: var(--greenn-transparent);
  color: var(--greenn) !important;
  border: 1px var(--greenn) solid;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  transition: all 0.3s;

  h3 {
    color: var(--greenn);
    margin: 0 !important;
    padding: 0 !important;
  }
}

.seller-no-ft:hover {
  background: var(--greenn2);
}

.seller-photo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-top: 11px;
  object-fit: cover;
}

.atualizar {
  background: transparent;
  padding: 10px;
  border: none;
  border-radius: 10px;
  width: 52px;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.07);
    color: #fff;
  }
}

.deletar {
  border: none;
  padding: 10px;
  border-radius: 10px;
  background: transparent;
  width: 52px;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.07);
    color: #fff;
  }
}

.email-icon {
  top: 15px !important;
  left: 15px !important;
}

#textarea {
  margin-bottom: 30px !important;
}

.mensagem {
  overflow-y: auto;
}

.mensagem::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.mensagem::-webkit-scrollbar-track {
  background-color: transparent;
}

.mensagem::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.is_loading {
  height: 100%;
  display: grid;
  place-content: center;
}

.lds-ellipsis {
  display: flex;
  margin: 0 auto;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

span {
  color: #81858e;
  display: block;
  font-size: 13px;
}

.product-list {
  overflow-x: scroll;
}

.product-list::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.product-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.product-list::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--greenn);
}

.lead-board {
  color: white;
  border-radius: 5px;
  padding: 2px 10px;
  width: fit-content;
  font-weight: 500;
  display: inline-block;
  margin-right: 5px;
}

.metrics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  .total {
    border-radius: 10px;
    padding: 10px;
    background-color: var(--greenn-transparent);
    display: grid;
    grid-template-columns: 35px 1fr;
    p {
      margin: 0 !important;
      color: var(--greenn);
      font-size: 12px;
      font-weight: 600;
    }

    h1 {
      margin: 0 !important;
      color: var(--greenn);
      line-height: 1;
      text-align: center;
    }
  }
}

.progress {
  background-color: white;
}

.progress_status_check {
  background: var(--greenn) !important;
  box-shadow: 1px 0px 20px var(--greenn) !important;
}
.progress-status {
  width: 60px;
  height: 2px;
  background: #bbb;
  transition: all 0.6s;
  animation: left-to-right;
}
.container-status {
  display: flex;
}
.check {
  margin-top: 2px;
  margin-left: 2px;
}
.active-steps {
  background-color: var(--greenn) !important;
  border-color: var(--greenn) !important;
  // .number-progress {
  //   display: none;
  // }
}
.progress-range {
  border-radius: 10px !important;
  width: 75px;
  height: 50px;
  background-color: #fff;
  display: block;
  border: 1px solid #bbb;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.etapas {
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
}
.number-progress {
  color: var(--white-medium);
  text-align: center;
  display: block;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 23px;
}

.title-body-activitie {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  margin: 0 !important;
  img {
    width: 15px;
  }
}
.container-body-activitie {
  padding: 20px;
  border: 1px solid var(--white-medium);
  border-radius: 10px;
}
.container-grid {
  padding: 15px 25px 25px;
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 20px;
  height: calc(100% - 240px);
  > div {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.grid-ativitie {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 5px;
  border-bottom: 1px solid var(--white-medium);
  .title-activie {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    .title-activie-id {
      color: #ccc;
      font-weight: 600;
      font-size: 16px;
    }
    .title-activie-img {
      width: 15px;
    }
    .title-activie-title {
      color: var(--gray01);
      font-weight: 400;
      font-size: 16px;
      white-space: nowrap;
    }
  }
}

.info_color {
  background-color: var(--bluee);
}

.danger_color {
  background-color: var(--red);
}

.warning_color {
  background-color: var(--orange);
}

.primary_color {
  background-color: var(--greenn-light);
}

.success_color {
  background-color: #2ed7ee;
}

.default_color {
  background-color: var(--gray01);
}
</style>

<style lang="scss">
#visualizar-lead {
  .input-tel > input {
    padding-left: 10px !important;
  }

  .vue-phone-number-input .select-country-container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 120px;
    flex: 0 0 120px;
    width: 105px;
    min-width: 105px;
    max-width: 105px;
  }
  .modal-dialog {
    border-radius: 15px;
    overflow: hidden;
  }

  .glyphicon-calendar {
    margin-right: 10px !important;
  }

  .TabHeader .nav-item a {
    padding: 15px !important;
    // margin-right: 1px;
    width: fit-content !important;
    border-radius: 0;
    border: 1px solid var(--white-medium) !important;
    border-right: none !important;
  }

  .TabHeader .nav-item a.active {
    border-bottom-color: var(--white) !important;
    background-color: var(--white);
  }

  #visualizar-lead-tab__BV_tab_controls_ {
    justify-content: flex-start !important;
  }

  #visualizar-lead-tab__BV_tab_container_ {
    border: 1px solid var(--white-medium);
    margin-top: -1px;
    height: calc(100% - 45px);
    max-height: calc(100% - 45px);
    padding: 0px;
    border-radius: 0 10px 10px;
    background-color: var(--white);
  }

  #visualizar-lead-tab__BV_tab_container_ > div,
  #visualizar-lead-tab__BV_tab_container_ > div > div {
    max-height: 100%;
    height: 100%;
    overflow: hidden;
  }

  a[aria-posinset="1"] {
    border-radius: 10px 0 0 0 !important;
  }

  .TabHeader .nav-item a[aria-posinset="6"] {
    border-radius: 0 10px 0 0 !important;
    border-right: 1px solid var(--white-medium) !important;
  }

  .wrapper,
  .card {
    background-color: transparent !important;
  }

  #visualizar-lead___BV_modal_body_ {
    height: 100%;
  }

  .multiselect__content-wrapper {
    z-index: 2 !important;
  }
  .fa-user:before {
    color: var(--greenn);
  }
  .openModalImg {
    width: 100%;
    height: 100%;
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      box-shadow: 3px 3px 10px #0003;
      border: 1px solid #0003;
      position: relative;
      overflow: hidden;
      width: fit-content;
      border-radius: 10px;
      .closeModalImg {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 30px;
        color: #008e7b;
        cursor: pointer;
        text-align: end;
      }
    }
  }
  .openModalImgVertical {
    img {
      height: 85vh;
      width: 100%;
      max-width: 80vw;
      object-fit: contain;
    }
  }
  .openModalImgHorizontal {
    img {
      width: 80vw;
      height: 100%;
      max-height: 85vh;
      object-fit: contain;
    }
  }
  #crm-infos {
    position: relative;
    .reason {
      position: absolute;
      top: 0px;
      right: -5px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 11px;
      width: fit-content;
    }
  }
  .loss {
    background: linear-gradient(354deg, #ffd9d921, #ff000021);
    border: 1px solid #ba0000;
  }
  .gain {
    background: linear-gradient(354deg, #d9ffed21, #00ff8621);
    border: 1px solid #00baab;
  }
  .checkout-link {
    color: var(--greenn);
    font-size: 14px;
  }
  .grid-address {
    display: grid;
    gap: 10px;
  }
}
</style>
